import styled from 'styled-components';

import { SecondaryText, theme } from '@/styles';

interface AddVideoWrapperProps {
  videoLinkValue?: string;
  isCommercial?: boolean;
}

export const AddVideoWrapper = styled.div<AddVideoWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 200px;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${theme.colors.modal.purple_300};
  border-radius: 12px;
  background-color: ${theme.colors.modal.purple_800};

  padding: 30px;
  cursor: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 'default' : 'pointer')};
  // cursor: ${({ isCommercial }) => (!isCommercial ? 'default' : 'pointer')};
  opacity: ${({ videoLinkValue }) => (videoLinkValue !== '' ? 0.5 : '')};
  // opacity: ${({ isCommercial }) => (!isCommercial ? 0.5 : '')};

  &:hover {
    border: 2px dashed ${theme.colors.modal.purple_300};
  }

  ${({ videoLinkValue, isCommercial }) =>
    videoLinkValue === '' &&
    isCommercial &&
    `
        &:hover {
          border: 2px dashed ${theme.colors.modal.purple_500};
        }
    `}
`;

export const AddVideoText = styled.span`
  font-size: 14px;
  color: ${theme.colors.modal.text.gray};

  width: max-content;

  @media (max-width: 1028px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const AddVideoText1 = styled(AddVideoText)<AddVideoWrapperProps>`
  cursor: pointer;
  color: ${theme.colors.modal.purple_500};

  &:hover {
    text-decoration: none;
  }

  ${({ videoLinkValue }) =>
    videoLinkValue === '' &&
    // isCommercial &&
    `
        &:hover {
        text-decoration: underline;
       }
    `}
`;

export const SecondaryTextAddVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SecondaryTextAddVideo = styled(SecondaryText)`
  text-align: center;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
`;

export const SelectedFileWrapper = styled.div`
  background-color: #a855f71a;
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
`;
