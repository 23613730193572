import { TimecodesFormWrapper } from './TimecodesForm.styled';

import { Buttons, TimecodesButtonWrapper, TimecodesWrapper } from '../../EditTimecodesPage.styled';
import { TitleTime } from '../TitleAndTime';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useUpdatePartialTimecodesAdminMutation } from '@/api';
import { Button, FullScreenLoader, RemoveTimecodes } from '@/components';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { useActions, useHandlingError } from '@/hooks';
import { Timecode, TransformedTimecodesResponse } from '@/types';
import { parseDuration } from '@/utils';

export const TimecodesForm = ({
  timecodes,
  publicId,
  goToTime,
}: TransformedTimecodesResponse & { goToTime: (time: number | string) => void }) => {
  const { videoId } = useParams();
  const { t } = useTranslation('pages');
  const { showNotification, openModal } = useActions();
  const [updateTimecodes, { isLoading: isUpdating, error }] = useUpdatePartialTimecodesAdminMutation();

  const { catchError } = useHandlingError();

  const methods = useForm<{ timecodes: Timecode[] }>({ defaultValues: { timecodes } });

  const onFormSubmit = async ({ timecodes }: { timecodes: Timecode[] }) => {
    try {
      const resultData = {
        data: {
          timecodes: timecodes.map((it) => ({ ...it, start: parseDuration(it.start as string) })),
        },
      };

      await updateTimecodes({ videoPk: videoId as string, publicId: publicId, body: resultData }).unwrap();

      showNotification({
        text: t('save_timecodes', { ns: 'success' }),
        severity: 'success',
      });
    } catch (e) {
      catchError(error, t('save_timecodes', { ns: 'error' }));
    }
  };

  const { fields, append, remove } = useFieldArray<{ timecodes: Timecode[] }, 'timecodes', 'key'>({
    control: methods.control,
    name: 'timecodes',
    keyName: 'key',
  });

  const handleDeleteTimecodes = async () => {
    openModal({ active: 'RemoveTimecodes' });
  };

  const handleAddNewTimecodes = () => {
    append({ start: '', text: '', title: '' });
  };

  return (
    <FormProvider {...methods}>
      {timecodes && (
        <TimecodesFormWrapper onSubmit={methods.handleSubmit(onFormSubmit)}>
          <TimecodesButtonWrapper>
            {isUpdating && <FullScreenLoader />}
            <TimecodesWrapper>
              {fields.map((timecode, index) => (
                <TitleTime key={timecode.key} index={index} remove={() => remove(index)} goToTime={goToTime} />
              ))}
            </TimecodesWrapper>
            <Button
              theme="white"
              style={{ padding: '13px', alignSelf: 'center' }}
              onClick={handleAddNewTimecodes}
              disabled={isUpdating}
            >
              <PlusBigIconBlack />
            </Button>
          </TimecodesButtonWrapper>
          <Buttons>
            <Button theme="black" type="submit" disabled={isUpdating || timecodes.length === 0}>
              {t('admin.save')}
            </Button>
            <Button onClick={handleDeleteTimecodes} disabled={timecodes.length === 0}>
              {t('admin.delete')}
            </Button>
          </Buttons>
        </TimecodesFormWrapper>
      )}
      <RemoveTimecodes publicId={publicId} videoPk={videoId || ''} />
    </FormProvider>
  );
};
