import styled from 'styled-components';

import { theme } from '@/styles';

export const TextWrapper = styled.div`
  line-height: 140%;
  margin-bottom: 15px;

  @media (min-width: 996px) {
    margin-bottom: 30px;
  }
`;

export const TextBlock = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: ${theme.colors.label.label_light};
  font-family: ${theme.fonts.geist};

  @media (max-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 21px;
  }

  @media (min-width: 1400px) {
    font-size: 26px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media (min-width: 993px) {
    gap: 20px;
  }

  @media (max-width: 576px) {
    & img {
      max-width: 60px;
    }
  }
`;

export const Icon = styled.img`
  display: flex;
  gap: 15px;
  max-width: 69px;

  @media (min-width: 768px) {
    gap: 20px;
    width: auto;
  }
`;

export const SectionContent = styled.div`
  @media (min-width: 576px) {
    margin: 0 auto;
    width: max-content;
  }
`;

export const ImgSlide = styled.img`
  display: block;
  padding-bottom: 20px;
  max-width: 90vw;

  @media (min-width: 576px) {
    padding-top: 0;
  }

  @media (min-width: 993px) {
    width: 45%;
    padding-top: 34px;
    padding-bottom: 0;
    max-width: 474px;
  }
`;
export const SpanWrapper = styled.div`
  margin-top: 30px;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;

  @media (min-width: 576px) {
    margin-bottom: 70px;
  }

  @media (min-width: 992px) {
    gap: 12px;
  }

  @media (min-width: 1400px) {
    gap: 30px;
    margin-bottom: 70px;
    max-width: max-content;
  }
  @media (min-width: 1600px) {
    gap: 240px;
  }
`;

export const ImageSlide = styled.img`
  max-width: 100%;
  width: 500px;

  @media (max-width: 1600px) {
  }

  @media (max-width: 576px) {
    width: 100%;
    flex: unset;
  }
`;

export const TextSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${theme.colors.label.label_light};
  font-family: ${theme.fonts.geist};

  @media (min-width: 993px) {
    font-size: 20px;
  }

  @media (min-width: 1200px) {
    //padding-left: 5vh;
  }
`;

export const TextFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px !important;
  max-width: 60%;
  justify-content: space-between;

  @media (max-width: 576px) {
    width: 100% !important;
    max-width: unset;
  }
`;
