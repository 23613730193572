import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FQAkStyled = styled.section`
  padding: 96px 0;
`;

export const TitleWrapper = styled(motion.div)`
  text-align: center;
  margin-bottom: 64px;
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 48px;
  color: transparent;
  font-weight: 700;
  background-clip: text;
  user-select: none;
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`;
