import styled, { css } from 'styled-components';
import { TextBlockScroll } from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';
import { ContentWrap } from '@/pages/VideoPage/VideoPage.styled';
import { IframeThemeType } from '@/types/iframeTypes';
import { theme, TitleH4 } from '@/styles';

export const TimecodesTitle = styled(TitleH4)<{
  isTarget?: boolean;
  iframeTheme?: IframeThemeType;
  isIframe?: boolean;
}>`
  background-color: ${(props) => (props.isTarget ? '#BE9F4B' : 'transparent')};

  font-weight: 600;
  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const Text = styled.span<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const TitleTimecodesWrapper = styled.div`
  margin-bottom: 16px;
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
`;

export const Timecodes = styled.ul``;

export const TimeTitle = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
`;

export const TimecodesTitleTab = styled(TimecodesTitle)<{
  isTarget?: boolean;
  isIframe?: boolean;
  iframeTheme?: IframeThemeType;
}>`
  background-color: ${(props) =>
    props.isTarget && !props.isIframe
      ? 'rgb(73,59,119)'
      : props.isTarget && props.isIframe && props.iframeTheme === IframeThemeType.light
      ? 'rgb(73 59 119 / 0.2)'
      : 'transparent'};
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 4px;
`;

export const ScrollBlock = styled(TextBlockScroll)<{
  iframeTheme?: IframeThemeType;
  isIframe?: boolean;
}>`
  height: 453px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
                color: rgb(26, 17, 69);

                ::-webkit-scrollbar-track {
                    background-color: rgb(228, 228, 255); /* цвет дорожки */
                }

                ::-webkit-scrollbar-thumb {
                    background-color: #d8b4fe; /* цвет плашки */
            `}
`;

export const TimecodesContentWrap = styled(ContentWrap)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  background-color: rgb(37 26 86);

  :hover {
    background-color: rgb(26 17 69);
  }

  &:hover ${TimecodesTitle} {
    color: rgb(192 132 252);
  }

  @media (min-width: 640px) {
    flex-direction: row;
    gap: 12px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgba(60, 64, 67, 0.07);

      :hover {
        background-color: rgb(228, 228, 255);
      }

      &:hover ${TimecodesTitle} {
        color: rgb(37 26 86);
      }
    `}
`;
