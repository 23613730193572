import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const FAQListStyled = styled.ul`
  display: grid;
  gap: 24px;
  max-width: 896px;
  margin: 0 auto;
  list-style: none;
`;

export const FAQItemContainer = styled.div<{ isCollapsed: boolean }>`
  padding: 32px;
  border: 1px solid ${theme.colors.border.purple_500_20};
  border-radius: 16px;
  background-color: ${theme.colors.background.purple_card};

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  :hover {
    border-color: ${theme.colors.border.purple_500_40};
  }

  svg {
    width: 24px;
    height: 24px;
    color: ${theme.colors.text.purple_400};
    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
  }
`;

export const FAQItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const FAQItemQuestion = styled.h3`
  color: ${theme.colors.text.white_100};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;

  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  :hover {
    color: ${theme.colors.text.purple_200};
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const FAQItemAnswer = styled(motion.p)`
  margin-top: 16px;
  color: ${theme.colors.text.purple_200};
  font-size: 18px;
  line-height: 28px;
`;
