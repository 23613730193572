import { memo } from 'react';

import { VideoCard } from '@/components/Card';
import { VideoCardProps } from '@/components/Card/VideoCard/VideoCard.props';
import { CardsList } from '@/styles';
import { Video, VideoInPlaylist } from '@/types';
import { IframeThemeType } from '@/types/iframeTypes';
import { useIframe } from '@/hooks/useIframe';

interface VideoListProps<T> {
  videos: T[];
  isLoading?: boolean;
  videoSettings?: Pick<VideoCardProps, 'withTime' | 'menu' | 'isPrivate'>;
  playlistId?: string;
  privateHash?: string;
}

export const VideoList = memo(
  <T extends Video | VideoInPlaylist>({ videos, videoSettings, playlistId, privateHash }: VideoListProps<T>) => {
    const { isIframe, iframeTheme, iframeLang, vendorId, showTest } = useIframe();

    return (
      <CardsList>
        {videos.map((video) => {
          return (
            <VideoCard
              isIframe={isIframe}
              iframeTheme={iframeTheme as IframeThemeType}
              key={video.publicId}
              movie={video}
              to={`watch${playlistId ? '/' + playlistId + '/' : '/'}${video.publicId}?t=${video.startsFrom ?? 0}${
                privateHash
                  ? `&linkHash=${privateHash}`
                  : isIframe
                  ? `&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}`
                  : ''
              }`}
              {...videoSettings}
            />
          );
        })}
      </CardsList>
    );
  },
);

VideoList.displayName = 'VideoList';
