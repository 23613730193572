import { Wrapper } from './AdminPage.styled';

import { Content } from '../LibraryPage';

import { memo } from 'react';

import { Outlet } from 'react-router-dom';

const AdminPage = () => {
  return (
    <Wrapper>
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};

export default memo(AdminPage);
