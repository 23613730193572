import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '@/pages/PlayListOutlet/components';
import { useIframe } from '@/hooks/useIframe';

const PlayListOutlet = () => {
  const { isIframe } = useIframe();
  return (
    <>
      {!isIframe && <BreadCrumbs />}
      {/* <VideosArrows /> */}
      <Outlet />
    </>
  );
};

export default PlayListOutlet;
