import { TabsList, TabsTrigger } from '@/pages/BalancePage/Tabs/Tabs.styled';
import { CreditCard, History } from '@mui/icons-material';
import { theme } from '@/styles';
import { useTranslation } from 'react-i18next';

export const Tabs = ({ onTabChange, tab }: { onTabChange: (newTab: number) => void; tab: number }) => {
  const { t } = useTranslation('pages');

  return (
    <TabsList>
      <TabsTrigger
        onClick={() => onTabChange(1)}
        style={{
          backgroundColor: `${tab === 1 ? theme.colors.modal.purple_100 : theme.colors.modal.purple_800}`,
          color: `${tab === 1 ? 'white' : theme.colors.modal.text.gray}`,
        }}
      >
        <CreditCard />
        {t('balance.tariffs')}
      </TabsTrigger>
      <TabsTrigger
        onClick={() => onTabChange(2)}
        style={{
          backgroundColor: `${tab === 2 ? theme.colors.modal.purple_100 : theme.colors.modal.purple_800}`,
          color: `${tab === 2 ? 'white' : theme.colors.modal.text.gray}`,
        }}
      >
        <History />
        {t('balance.history_buys')}
      </TabsTrigger>
    </TabsList>
  );
};
