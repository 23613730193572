export const Clock = ({ width, height, color }: { width?: string; height?: string; color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '14'}
      height={height ?? '14'}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color ?? 'rgb(168 85 247)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-clock"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
  );
};
