import {
  ButtonsWrapper,
  ButtonsAvatarWrapper,
  Form,
  Inner,
  FieldsWrapper,
  PhotoInputWrapper,
  MyPhotoInput,
} from './ProfilePage.styled';

import Avatar from '../../components/Shared/Avatar';
import Button from '../../components/Shared/Buttons/Button/Button';
import TrashButton from '../../components/Shared/Buttons/TrashButton/TrashButton';
import FullScreenLoader from '../../components/Shared/FullScreenLoader/FullScreenLoader';
import MyInput from '../../components/Shared/MyInput/MyInput';
import { Header, HeaderTitle } from '../LibraryPage';

import { ChangeEvent, memo, useEffect, useState } from 'react';

// import ReactGA from 'react-ga4';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { usersAPI } from '@/api';
import { RemoveUser, CurrentPassword, WithBackIcon } from '@/components';
import { useAppSelector, useHandlingError, useActions } from '@/hooks/';
import { UpdateAccountUser } from '@/types';
import { setAvatarFileName } from '@/utils';

const ProfilePage = () => {
  const { t } = useTranslation('header');
  const { openModal, showNotification } = useActions();
  const { data: dataMe, error: errorMe } = usersAPI.useGetUserMeQuery();

  const { user_id: id, avatar, username } = useAppSelector((state) => state.user);

  const [viewAvatar, setViewAvatar] = useState<string | null | undefined>(avatar);

  const { data, isLoading, error } = usersAPI.useGetUserByIdQuery({ id });

  const [updateUser, result] = usersAPI.useUpdateUserMutation();
  const [updateUserMe] = usersAPI.useUpdatePartialUserMeMutation();

  const { catchError } = useHandlingError();

  useEffect(() => {
    catchError(error || errorMe);
  }, [error, errorMe]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log(isSuccess, data?.avatar);
  //     setViewAvatar(data?.avatar);
  //   }
  // }, [isSuccess]);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    setFocus,
    reset,
    resetField,
  } = useForm<UpdateAccountUser>({ defaultValues: { avatar: '' }, mode: 'onChange' });

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files instanceof FileList && e.target.files[0]) {
      const file = e.target.files[0];
      setViewAvatar(window.URL.createObjectURL(file));
    }
  };

  const onSubmit: SubmitHandler<UpdateAccountUser> = async (body) => {
    try {
      let formBody: UpdateAccountUser | FormData = Object.assign({}, body);

      console.log({ viewAvatar });

      if (body.newPassword) {
        formBody.newPassword = body.newPassword;
        openModal({ active: 'currentpassword', data: { newPassword: body.newPassword } });
      }

      if (body.avatar && body.avatar.length === 1) {
        const file = body.avatar[0] as File;

        const avatarFormData = new FormData();
        avatarFormData.set('avatar', file, setAvatarFileName(file.name));
        const data = await updateUserMe(avatarFormData as Partial<UpdateAccountUser>).unwrap();
        setViewAvatar(data.avatar);
      }
      if (!viewAvatar) {
        await updateUserMe({ avatar: null }).unwrap();
        setViewAvatar(null);
      }

      const formData = new FormData();
      formData.set('email', body.email);
      formData.set('username', body.username);
      formBody = formData;

      await updateUser({ id, body: formBody }).unwrap();

      showNotification({
        text: t('data', { ns: 'success' }),
        severity: 'success',
      });
      // ReactGA.event({ category: 'profile settings', action: 'save_settings' });

      reset();
    } catch (e) {
      catchError(e);
    }
  };

  const resetAvatar = () => {
    setViewAvatar(avatar);
    resetField('avatar');
  };

  const deleteFileUpload = () => {
    resetAvatar();
  };

  const deleteUserAvatar = () => {
    setValue('avatar', '', { shouldDirty: true });
    setFocus('avatar');
    setViewAvatar(null);
  };

  const deleteFile = () => {
    if (avatar && getValues('avatar')) {
      deleteFileUpload();
    } else if (avatar && !getValues('avatar')) {
      deleteUserAvatar();
    } else if (!avatar && getValues('avatar')) {
      deleteFileUpload();
    }
  };

  console.log({ avatar, viewAvatar, dataMe: dataMe?.avatar });

  return (
    <>
      <Header>
        <HeaderTitle>{t('menu.set')}</HeaderTitle>
      </Header>
      <Inner>
        {error && <Navigate to="/*" />}
        {data && (
          <Form onSubmit={handleSubmit(onSubmit)} name="profile" id="form_profile" autoComplete="off">
            <PhotoInputWrapper content={t('account.photo', { ns: 'pages' })}>
              <MyPhotoInput
                type="file"
                {...register('avatar', {
                  onChange(event: ChangeEvent<HTMLInputElement>) {
                    handleImage(event);
                  },
                  validate: {
                    lessThan5MB: (files: unknown) => {
                      if (files instanceof FileList && files[0] && files[0].size) {
                        return files[0].size < 5000000 || t('size', { ns: 'error', size: 5 });
                      } else if (errors.avatar) {
                        resetAvatar();
                      }
                    },
                    acceptedFormats: (files: unknown) => {
                      if (files instanceof FileList && files[0] && files[0].type) {
                        return (
                          ['image/jpeg', 'image/png'].includes(files[0].type) ||
                          t('format', { ns: 'error', format: 'PNG, JPEG' })
                        );
                      } else if (errors.avatar) {
                        resetAvatar();
                      }
                    },
                  },
                })}
                error={errors.avatar}
                accept={'.jpg, .png'}
              >
                <Avatar
                  size="m"
                  url={viewAvatar}
                  name={username}
                  auth
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 0,
                  }}
                />
              </MyPhotoInput>
              <ButtonsAvatarWrapper>
                {viewAvatar && viewAvatar === avatar && <TrashButton bg="red" onClick={deleteFile} />}
                {viewAvatar && dataMe?.avatar && viewAvatar !== dataMe?.avatar && getValues('avatar') && (
                  <WithBackIcon onClick={resetAvatar} />
                )}
              </ButtonsAvatarWrapper>
            </PhotoInputWrapper>
            <FieldsWrapper>
              <MyInput
                {...register('username', {
                  required: { value: true, message: t('req_field', { ns: 'error' }) },
                  minLength: { value: 3, message: t('min_char', { ns: 'error', length: 3 }) },
                  maxLength: { value: 256, message: t('min_char', { ns: 'error', length: 256 }) },
                  value: username,
                })}
                label={t('form.name', { ns: 'components' })}
                placeholder={t('form.name', { ns: 'components' })}
                error={errors.username}
              />
              <MyInput
                {...register('email', {
                  required: { value: true, message: t('req_field', { ns: 'error' }) },
                  minLength: { value: 5, message: t('min_char', { ns: 'error', length: 5 }) },
                  maxLength: { value: 256, message: t('min_char', { ns: 'error', length: 256 }) },
                  pattern: {
                    value: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
                    message: t('incor_email', { ns: 'error' }),
                  },
                  value: data.email,
                })}
                label={t('form.email', { ns: 'components' })}
                disabled={true}
                placeholder="example@email.com"
                error={errors.email}
              />
              <MyInput
                {...register('newPassword', {
                  required: { value: false, message: t('req_field', { ns: 'error' }) },
                  minLength: { value: 6, message: t('min_char', { ns: 'error', length: 6 }) },
                  maxLength: { value: 128, message: t('min_char', { ns: 'error', length: 128 }) },
                })}
                type="password"
                label={t('form.password', { ns: 'components' })}
                placeholder={t('form.password', { ns: 'components' })}
                error={errors.newPassword}
                autoComplete="off"
              />
            </FieldsWrapper>
            <ButtonsWrapper>
              <Button
                theme="white"
                variant="outlined"
                type="submit"
                disabled={!isDirty}
                // disabled={!isDirty}
              >
                {t('form.save', { ns: 'components' })}
              </Button>
              <Button type="button" theme="red" onClick={() => openModal({ active: 'RemoveUser' })}>
                {t('form.del', { ns: 'components' })}
              </Button>
            </ButtonsWrapper>
            <CurrentPassword />
          </Form>
        )}
      </Inner>
      {(result.isLoading || isLoading) && <FullScreenLoader />}
      <RemoveUser />
    </>
  );
};

export default memo(ProfilePage);
