import styled from 'styled-components';
import { ContentCard, ContentTitle } from '@/pages/BalancePage/BalancePage.styled';
import { theme } from '@/styles';
import { ContentCardBlock } from '@/pages/BalancePage/TariffsTab/TarifItem/TarifItem.styled';
import Button from '@/components/Shared/Buttons/Button/Button';

export const Container = styled(ContentCard)`
  position: relative;
  margin-top: 20px;
`;

export const CalculatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Balance = styled.div`
  padding: 0 16px;
  background-color: ${theme.colors.modal.purple_800};
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TitleDescBalance = styled.div`
  color: ${theme.colors.modal.text.purple};
  font-size: 20px;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CountTitle = styled(ContentTitle)`
  font-size: 20px;
  font-weight: 600;
  align-self: flex-end;
  margin-bottom: 0px;
`;

export const TariffsTitle = styled(ContentTitle)`
  font-size: 18px;
  margin-bottom: 0px;
`;

export const InfoTariffs = styled.div`
  gap: 8px;
  display: flex;
  align-items: baseline;
`;

export const ContentTitle1 = styled(ContentTitle)`
  margin-bottom: 0px;
`;

export const ContentCardBlockCalculator = styled(ContentCardBlock)`
  width: 300px;
`;

export const ContentCardBlockCalculatorTotal = styled(ContentCardBlock)`
  margin-top: 5px;
  width: 100%;

  :hover {
    border-color: ${theme.colors.modal.purple_300};
  }
`;

export const Block = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
`;

export const BlockInfo = styled(ContentCardBlock)`
  width: 100%;
  background-color: ${theme.colors.modal.purple_800};
  border-radius: 12px;
  padding: 20px;
  margin-top: 16px;
  border: none;

  :hover {
    border-color: transparent;
  }
`;

export const BlockTotal = styled(Block)`
  display: flex;
  gap: 15px;
  width: 100%;
`;

export const TitleDescBalance1 = styled(TitleDescBalance)`
  font-size: 18px;
`;

export const BalanceTotal = styled(Balance)`
  padding: 0;
  display: flex;
  gap: 15px;
`;

export const CountTotal = styled(Count)`
  background-color: #1a1145;
  padding: 2px 12px;
  border-radius: 10px;
`;

export const ButtonCount = styled(Button)`
  padding: 24px;
`;
