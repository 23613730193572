import { AvatarWithMenu } from './AvatarMenu';
import { HeaderStyled, Inner } from './Header.styled';
import logo from './logo.svg';
import visaver_logo from './visaver.png';

import { memo } from 'react';

// import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Container } from '@/components';
import { useMatchMedia } from '@/hooks';
// import { ModalType } from '@/store/slices/modalsSlice';

// import { LogoIcon } from './LogoIcon';

export const Header = memo(() => {
  // const { t } = useTranslation('header');
  // const { resetSearch, openModal } = useActions();
  // const isAuth = useAppSelector((state) => state.user.isAuth);
  // const [searchInputMobileIsOpen, setSearchInputMobileIsOpen] = useState<boolean>(false);
  // const navigate = useNavigate();

  // const inputMobileCloseHandler = () => {
  //   setSearchInputMobileIsOpen(false);
  //   resetSearch();
  // };

  const isMobile = useMatchMedia('(max-width: 768px)');

  // const openModalHandler = (modal: ModalType) => {
  //   if (isAuth) {
  //     openModal({ active: modal });
  //   } else {
  //     openModal({ active: 'login' });
  //   }
  // };

  return (
    <HeaderStyled>
      <Container>
        <Inner>
          <Link to="/" style={{ flexShrink: 0, marginRight: '24px', alignItems: 'center', display: 'flex' }}>
            <img src={logo} alt="ViSaver Logo" width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
            <img src={visaver_logo} alt="ViSaver" width={150} />
          </Link>
          {isMobile ? (
            <>
              {/* <Menu className="w-5 h-5" /> */}
              {/* <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="text-white w-10 h-10 rounded-lg p-0">
                      <Menu className="w-5 h-5" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-56 mt-2 bg-[#1a1145] text-white border-purple-900">
                    <DropdownMenuItem className="py-3">
                      <Link href="#how-it-works" className="flex items-center w-full">
                        <Info className="w-5 h-5 mr-3" /> Как работает
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="py-3">
                      <Link href="#get-started" className="flex items-center w-full">
                        <Play className="w-5 h-5 mr-3" /> Как начать?
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="py-3">
                      <Link href="#contacts" className="flex items-center w-full">
                        <Phone className="w-5 h-5 mr-3" /> Контакты
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="py-3">
                      <Building2 className="w-5 h-5 mr-3" /> Для организаций
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu> */}
            </>
          ) : (
            <>
              {/* Desktop Header */}
              {/* <Link to="/" style={{ flexShrink: 0, marginRight: '24px' }}>
                <img src={logo} alt="ViSaver Logo" width={40} height={40} />
              </Link> */}

              <nav className="flex items-center space-x-8 mr-auto">
                <a href="#how-it-works" className="text-white hover:text-purple-300 transition-colors text-base">
                  Как работает
                </a>
                <a href="#get-started" className="text-white hover:text-purple-300 transition-colors text-base">
                  Как начать?
                </a>
                <a href="#contacts" className="text-white hover:text-purple-300 transition-colors text-base">
                  Контакты
                </a>
              </nav>
            </>
          )}
          <div style={{ flexShrink: 0 }}>
            <AvatarWithMenu />
          </div>
        </Inner>
      </Container>
    </HeaderStyled>
  );
});

Header.displayName = 'Header';
