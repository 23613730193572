import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import Rutube from 'rutube-player';

const rt = new Rutube();

let timeId: number;

export const RutubeVideoPlayer = forwardRef<
  { goToTime: (time: number) => void },
  { videoId: string; startsFrom?: number; height: string; setCurrentTime?: (time: number) => void }
>(({ videoId, height, startsFrom = 0, setCurrentTime }, ref) => {
  const iframe = useRef<HTMLDivElement>(null);

  const goToTime = useCallback(
    async (time: number) => {
      await rt.seekTo({ time });
      await rt.play();
    },
    [rt],
  );

  useImperativeHandle(
    ref,
    () => ({
      goToTime,
    }),
    [goToTime],
  );

  useEffect(() => {
    rt.Player('player', {
      height,
      videoId,
      events: {
        onReady: () => goToTime(startsFrom),
        onStateChange: onPlayerStateChange,
      },
    });

    function onPlayerStateChange(event: any) {
      if (event.playerState.PLAYING) {
        const getTime = () => {
          setCurrentTime?.(Math.round(rt.currentDuration()) as number);
          requestIdleCallback(getTime);
        };

        timeId = requestIdleCallback(getTime);
      }
      if (event.playerState.PAUSED || event.playerState.ENDED) {
        cancelIdleCallback(timeId);
      }
    }

    const el = iframe.current?.children[0];
    if (el) {
      (el as HTMLIFrameElement).style.width = '100%';
    }

    return () => {
      iframe.current?.children[0].remove();
    };
  }, []);

  return <div id="player" ref={iframe} style={{ width: '100%', height: '100%' }}></div>;
});

RutubeVideoPlayer.displayName = 'RutubeVideoPlayer';
