import { QuizTab } from './QuizTab/QuizTab';
import { SearchInVideoInputV2 } from '@/pages/VideoPage/SearchInVideoInputV2';
import { SummaryTab } from './SummaryTab/SummaryTab';
import { TimecodesTab } from './TimecodesTab/TimecodesTab';
import { TranscriptVideo } from './TranscriptVideo/TranscriptVideo';
import { VideoFragmentCard } from '@/components/Card/VideoFragmentCard/VideoFragmentCard';
import { VideoMaterialTabs } from './VideoMaterialTabs/VideoMaterialTabs';
import {
  Container,
  Content,
  ContentBlock,
  Inner,
  MaterialWrapper,
  StyledLink,
  Title,
  Video,
  VideoCardV2Wrapper,
  VideoPlayer,
} from './VideoPage.styled';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { videosAPI } from '@/api';
import { FileVideoPlayer, RutubeVideoPlayer, VkVideoPlayer, YouTubeVideoPlayer } from '@/components';
import { CopyIframeButton } from '@/components/CopyIframeButton/CopyIframeButton';
import { useActions, useAppSelector, useHandlingError, useIframe, useIframeHeight } from '@/hooks';
import { IframeLangType, IframeThemeType } from '@/types';
import { ButtonTranscript } from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';

export const VideoPage = () => {
  useIframeHeight();
  const [isAuth, isCommercial] = useAppSelector((state) => [state.user.isAuth, state.user.isCommercial]);
  // const isCommercial = useAppSelector((state) => state.user.isCommercial);
  const { isIframe, iframeTheme, iframeLang, showTest } = useIframe();
  const { showNotification } = useActions();
  const { id, hash } = useParams();
  const [currentTime, setCurrentTime] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const isLandingPage = location.pathname.includes('landing');
  const videosPage = location.pathname.includes('videos/watch');
  const cameFromPlaylist = location.state?.toString().includes('playlist');
  const videoId = isLandingPage ? 'e292aa3b-a639-4150-be83-77e72ed95680' : id ?? '';
  const [params, setParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const search = params.get('search');
  const [tab, setTab] = useState(1);
  const { catchError } = useHandlingError();
  const iframe = useRef<{ goToTime: (time: number) => void } | null>(null);
  const iframeWrapper = useRef<HTMLDivElement>(null);

  const {
    data: video,
    isLoading: isVideoLoaging,
    isError: videoError,
  } = videosAPI.useGetMovieByIdQuery({ id: videoId });

  const [
    getSearchInVideo,
    { data: searchInVideo, isLoading: isSearchLoading, error: searchError, isSuccess: searchSuccess },
  ] = videosAPI.useLazyGetFullSearchInVideoQuery();

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchInVideo({ query, videoId: video?.publicId ?? '' });
    },
    [video],
  );

  const goToTime = useCallback(
    (time: number) => {
      iframe.current?.goToTime(time);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  const startsForm = useMemo(() => {
    const time = params.get('t');
    return time ? parseInt(time) : 0;
  }, [params]);

  useEffect(() => {
    if (!isAuth && !iframeTheme) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  useEffect(() => {
    switch (iframeLang) {
      case IframeLangType.RU:
        i18n.changeLanguage('ru');
        break;
      case IframeLangType.EN:
        i18n.changeLanguage('en');
        break;
      default:
        i18n.changeLanguage('en');
    }
  }, [iframeLang]);

  useEffect(() => {
    catchError(videoError || searchError);
  }, [videoError, searchError]);

  return (
    <Container isIframe={isIframe}>
      {videosPage && <StyledLink to={'/videos'}>Назад</StyledLink>}
      <Inner isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        {isIframe && cameFromPlaylist && (
          <ButtonTranscript
            style={{ marginBottom: '20px' }}
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            onClick={() => navigate(-1)}
          >
            Назад
          </ButtonTranscript>
        )}
        {video && (
          <Content>
            <Video>
              <VideoPlayer isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                {video.source === 'RUTUBE' && (
                  <RutubeVideoPlayer
                    ref={iframe}
                    height={'100%'}
                    videoId={video.videoId}
                    startsFrom={startsForm}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                    // goToTime={goToTime}
                  />
                )}

                {video && video.source === 'YOUTUBE' && (
                  <YouTubeVideoPlayer
                    ref={iframe}
                    id={video.videoId}
                    title={video?.title}
                    startsFrom={video.startsFrom ?? startsForm}
                    height={'100%'}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}

                {video && video.source === 'VK' && (
                  <VkVideoPlayer
                    ref={iframe}
                    title={video?.title}
                    startsFrom={startsForm}
                    originLink={video.originLink}
                    height={'100%'}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}
                {video && video.source === 'UPLOADED' && (
                  <FileVideoPlayer
                    ref={iframe}
                    height={'100%'}
                    url={video?.originLink}
                    startsFrom={startsForm}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}
              </VideoPlayer>
              <SearchInVideoInputV2
                getSearch={getSearchVideosHandler}
                isIframe={isIframe}
                iframeTheme={iframeTheme as IframeThemeType}
              />

              {search && searchInVideo && searchSuccess && video && (
                <>
                  {searchInVideo.length === 0 && (
                    <div>
                      <Title
                        isIframe={isIframe}
                        iframeTheme={iframeTheme as IframeThemeType}
                        style={{ fontSize: '17px' }}
                      >
                        {t('main.no_result', { ns: 'pages' })}
                      </Title>
                    </div>
                  )}
                  {searchInVideo.length > 0 &&
                    searchInVideo.map((fragment) => {
                      return (
                        <VideoCardV2Wrapper
                          isIframe={isIframe}
                          iframeTheme={iframeTheme as IframeThemeType}
                          style={{ marginTop: '8px', padding: '15px' }}
                          key={fragment.publicId}
                        >
                          <ContentBlock isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                            {fragment.cues.map((cue, i) => {
                              if (fragment.publicId === video.publicId) {
                                return (
                                  <VideoFragmentCard
                                    fragment={cue}
                                    key={fragment.publicId + i}
                                    goToTime={goToTime}
                                    videoPreview={fragment.thumbnailUrl}
                                    isIframe={isIframe}
                                    iframeTheme={iframeTheme as IframeThemeType}
                                  />
                                );
                              }
                            })}
                          </ContentBlock>
                        </VideoCardV2Wrapper>
                      );
                    })}
                  {search && isSearchLoading && (
                    <div style={{ margin: '0 auto' }}>
                      <CircularProgress color="secondary" size={40} />
                    </div>
                  )}
                </>
              )}
            </Video>
            <MaterialWrapper>
              <VideoMaterialTabs
                video={video ?? undefined}
                tab={tab}
                onTabChange={setTab}
                iframeTheme={iframeTheme as IframeThemeType}
                isIframe={isIframe}
                showTest={showTest}
              />
              {tab === 1 && video && (
                <TranscriptVideo
                  transcriptStatus={video && video.transcriptionStatus}
                  id={videoId}
                  isIframe={isIframe}
                  iframeTheme={iframeTheme as IframeThemeType}
                />
              )}
              {tab === 2 && video && (
                <TimecodesTab
                  timecodesStatus={video && video.timecodesStatus}
                  id={videoId}
                  hash={hash}
                  setTime={goToTime}
                  currentTime={currentTime}
                  titleVideo={video ? video.title : ''}
                  isIframe={isIframe}
                  iframeTheme={iframeTheme as IframeThemeType}
                />
              )}
              {tab === 3 && video && (
                <SummaryTab
                  summaryStatus={video && video.summaryStatus}
                  videoPublicId={videoId}
                  hash={hash}
                  isIframe={isIframe}
                  iframeTheme={iframeTheme as IframeThemeType}
                />
              )}
              {tab === 4 && video && (
                <QuizTab
                  quizStatus={video && video.quizzStatus}
                  videoPublicId={videoId}
                  hash={hash}
                  goToTime={(time: string | number) => goToTime(time as number)}
                  isIframe={isIframe}
                  iframeTheme={iframeTheme as IframeThemeType}
                />
              )}
            </MaterialWrapper>
          </Content>
        )}
        {isVideoLoaging && (
          <div style={{ margin: '0 auto' }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}
        {isCommercial && isAuth && !isIframe && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <CopyIframeButton videoId={id ?? ''} />
          </div>
        )}
      </Inner>
    </Container>
  );
};
