import { AvatarMenuArrow, AvatarWrapper } from './AvatarWithMenu.styled';

import { Reducer, memo, useReducer } from 'react';

import { Info, Menu, Phone, Play } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Avatar from '@/components/Shared/Avatar';
import DropDown from '@/components/Shared/DropDown';
import DropDownMenuItem, { DropDownIMenuItemProps } from '@/components/Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import { ArrowSmall } from '@/components/SvgIcons';
import SignInIcon from '@/components/SvgIcons/SignInIcon';
import SignUpIcon from '@/components/SvgIcons/SignUpIcon';
import { useAppSearchParams, useMatchMedia } from '@/hooks';

export const AvatarWithMenu = memo(() => {
  const { t } = useTranslation('header');

  const { setAppParams } = useAppSearchParams();
  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const toggleHandler = () => {
    setOpened(opened);
  };

  const isMobile = useMatchMedia('(max-width: 640px)');

  // const { isAuth: auth, username } = useAppSelector((state) => state.user);

  // const { data } = usersAPI.useGetUserMeQuery(undefined, { skip: !auth });

  // const changeLanguage = () => {
  //   switch (i18n.language) {
  //     case 'ru':
  //       i18n.changeLanguage('en');
  //       break;
  //     case 'en':
  //       i18n.changeLanguage('ru');
  //       break;
  //     default:
  //       i18n.changeLanguage('en');
  //   }
  // };

  const items = [
    {
      text: <>{t('menu.in')}</>,
      icon: <SignInIcon />,
      onClick: () => setAppParams('popup', 'login'),
    },
    // {
    //   text: t('menu.lang'),
    //   icon: <LanguageIcon />,
    //   onClick: changeLanguage,
    // },
    {
      text: <>{t('menu.up')}</>,
      icon: <SignUpIcon />,
      onClick: () => setAppParams('popup', 'registration'),
    },
  ] as unknown as DropDownIMenuItemProps[];

  const links = [
    {
      text: (
        <a href="#how-it-works" className="text-white hover:text-purple-300 transition-colors text-base">
          Как работает
        </a>
      ),
      icon: <Info />,
      onClick: undefined,
    },
    {
      text: (
        <a href="#get-started" className="text-white hover:text-purple-300 transition-colors text-base">
          Как начать?
        </a>
      ),
      icon: <Play />,
      onClick: undefined,
    },
    {
      text: (
        <a href="#contacts" className="text-white hover:text-purple-300 transition-colors text-base">
          Контакты
        </a>
      ),
      icon: <Phone />,
      onClick: undefined,
    },
  ] as unknown as DropDownIMenuItemProps[];

  return (
    <>
      <DropDown
        isOpen={opened}
        toggleOpen={toggleHandler}
        items={(isMobile ? links.concat(items) : items).map((item, index) => (
          <DropDownMenuItem key={index} icon={item.icon} text={item.text} onClick={item?.onClick} />
        ))}
      >
        {isMobile ? (
          <Menu />
        ) : (
          <AvatarWrapper open={opened}>
            <Avatar size="s" />
            <AvatarMenuArrow>
              <ArrowSmall className="arrow" />
            </AvatarMenuArrow>
          </AvatarWrapper>
        )}
      </DropDown>
    </>
  );
});

AvatarWithMenu.displayName = 'AvatarWithMenu';
