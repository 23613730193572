import styled, { css, keyframes } from 'styled-components';

const marqueeAnimation = keyframes`
100% { transform: translateX(calc(-100% - 16px)); }
`;

export const MarqueeStyled = styled.div<{ fade: boolean }>`
  position: relative;
  overflow: hidden;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  margin-bottom: 32px;

  transition-duration: 30s;

  ${({ fade }) => {
    if (fade) {
      return css`
        mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);
        -webkit-mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);
      `;
    }
  }}

  @media screen and (min-width: 640px) {
    margin-bottom: 48px;
  }
`;

export const MarqueeInner = styled.div<{ pauseOnHover: boolean; duration: string; reverse: boolean }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  gap: 16px;
  padding: 0 16px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  min-width: 100%;

  animation: ${marqueeAnimation} ${({ duration }) => duration} linear infinite
    ${({ reverse }) => (reverse ? 'reverse' : 'normal')};

  animation-play-state: running;

  :hover {
    animation-play-state: ${({ pauseOnHover }) => (pauseOnHover ? 'paused' : 'running')};
  }
`;
