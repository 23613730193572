import { useEffect } from 'react';

import { Pagination } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useGetMyVideosQuery } from '@/api';
import { FullScreenLoader, RemoveVideo } from '@/components';
import { VideoCard } from '@/components/Card';
import { useHandlingError, usePagination } from '@/hooks';
import { CardItem, CardList, InfoTitle, InfoWrapper } from '@/pages/LibraryPage';
import { PaginationWrapper } from '@/styles';

export const VideosWrapper = ({ isAdmin }: { isAdmin?: boolean }) => {
  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();

  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const { data: videos, isLoading, isSuccess, error } = useGetMyVideosQuery({ params: { page } });

  useEffect(() => {
    if (isSuccess) {
      setCount(videos.results.length === 0 ? 1 : videos.count);
    }
  }, [videos]);

  useEffect(() => {
    catchError(error);
  }, [error]);

  return (
    <>
      <CardList>
        {isSuccess &&
          videos.results.map((movie, index) => (
            /** убрать index поссле фикса дублей */
            <CardItem key={`${movie.publicId}-${index}`}>
              <VideoCard
                movie={movie}
                to={isAdmin ? `edit/${movie.publicId}/timecodes` : `watch/${movie.publicId}?t=${movie.startsFrom ?? 0}`}
                menu
              />
            </CardItem>
          ))}
      </CardList>
      {videos && videos.results.length > 0 && (
        <PaginationWrapper>
          {pages != 1 && (
            <Pagination
              count={pages}
              page={page}
              size={isDesktop ? 'medium' : 'small'}
              color="primary"
              onChange={handleChangePage}
            />
          )}
        </PaginationWrapper>
      )}
      {isSuccess && videos.results.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_v')}</InfoTitle>
        </InfoWrapper>
      )}
      {isLoading && <FullScreenLoader />}
      <RemoveVideo />
    </>
  );
};
