import { StatusType } from '@/types';
import { useTranslation } from 'react-i18next';
import { useGetVideoAllQuizzesQuery } from '@/api';
import { useAppSelector, useHandlingError } from '@/hooks';
import { useEffect } from 'react';
import { TitleTranscript, TitleTranscriptWrapper } from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';
import { ContentWrap, VideoCardV2Wrapper } from '@/pages/VideoPage/VideoPage.styled';
import { QuestionPage } from '@/pages/VideoPage/QuizTab/QuestionPage/QuestionPage';
import { QuestionInfo } from '@/pages/VideoPage/QuizTab/QuestionPage/QuestionPage.styled';
import CircularProgress from '@mui/material/CircularProgress';
import { IframeThemeType } from '@/types/iframeTypes';
import { Text } from '@/pages/VideoPage/TimecodesTab/TimecodesTab.styled';
import { Complete } from '@/pages/VideoPage/QuizTab/Complete';

interface QuizProps {
  publicId?: string;
  playlistId?: string;
  goToTime: (time: number) => void;
  hash?: string;
  videoPublicId?: string;
  isVideoFile?: boolean;
  playlistType?: string;
  quizStatus?: StatusType | undefined;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const QuizTab = ({
  publicId = '',
  playlistId = '',
  goToTime,
  hash,
  videoPublicId = '',
  quizStatus,
  iframeTheme,
  isIframe,
}: QuizProps) => {
  const { t } = useTranslation('components');

  const { data, isLoading, error, isError } = useGetVideoAllQuizzesQuery(
    { playlistId, videoPublicId, hash },
    { skip: Boolean(publicId) },
  );
  const { catchError } = useHandlingError();

  const [activeQuestionIndex, questions, done] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
    state.quiz.done,
  ]);

  useEffect(() => {
    catchError(error);
  }, [error]);

  if (isError || quizStatus === 'NOT_STARTED') {
    return (
      <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        Видео обрабатывается
      </Text>
    );
  }

  return (
    <VideoCardV2Wrapper style={{ minHeight: '584px' }} isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      <TitleTranscriptWrapper>
        <TitleTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          {t('title_quiz')}
        </TitleTranscript>
        <QuestionInfo isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{`${t('quizPage.question_quiz', {
          ns: 'pages',
          activeQuestionIndex: activeQuestionIndex + 1,
          questionsLength: questions.length,
        })}`}</QuestionInfo>
      </TitleTranscriptWrapper>
      <ContentWrap isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        {quizStatus === 'FAILED' && <Text>Видео обрабатывается</Text>}
        {isLoading && (
          <div style={{ margin: '0 auto' }}>
            <CircularProgress color="secondary" size={40} />
          </div>
        )}
        {data && data.data.length > 0 && !done && quizStatus === 'DONE' && (
          <QuestionPage
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            {...questions[activeQuestionIndex]}
            goToTime={goToTime}
          />
        )}
        {done && <Complete isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />}
      </ContentWrap>
    </VideoCardV2Wrapper>
  );
};
