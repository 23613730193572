import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  LinkDescription,
  TextWrapper,
  TimecodesDescriptionText,
} from '@/pages/VideoPage/TimecodesTab/TimecodesDescription/TimecodesDescription.styled';
import { IframeThemeType } from '@/types/iframeTypes';
import { Text } from '@/pages/VideoPage/TimecodesTab/TimecodesTab.styled';

export const TimecodesDescription = ({
  text,
  isIframe,
  iframeTheme,
}: {
  text: string;
  isIframe?: boolean;
  iframeTheme?: IframeThemeType;
}) => {
  const { t } = useTranslation('components');
  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onReadMoreClick = () => {
    setIsCollapsedText((p) => !p);
  };

  if (text.length < 100)
    return (
      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>
    );

  return (
    <div>
      {isCollapsedText ? (
        <span>
          <TimecodesDescriptionText isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{`${text.slice(
            0,
            100,
          )}...`}</TimecodesDescriptionText>
          <LinkDescription isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={onReadMoreClick}>
            {t('timecodes_desc_more')}
          </LinkDescription>
        </span>
      ) : (
        <span>
          <TimecodesDescriptionText isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {text}
          </TimecodesDescriptionText>
          <LinkDescription isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={onReadMoreClick}>
            {t('timecodes_button')}
          </LinkDescription>
        </span>
      )}
    </div>
  );
};
