import { api } from './api';

import type {
  TimecodesResponse,
  TransformedTimecodesResponse,
  BaseAdminRequest,
  BaseTimecodesRequest,
  GetList,
  Transcript,
  AdminSummary,
} from '@/types';

import { formatDuration } from '@/utils';

const PATH = 'control-panels';

export const adminAPI = api.injectEndpoints({
  endpoints: (build) => ({
    // таймкоды
    getTimecodesAdmin: build.query<TransformedTimecodesResponse, BaseAdminRequest>({
      query: ({ videoPk }) => ({
        url: `/videos/${videoPk}/timecodes/`,
        method: 'GET',
      }),
      transformResponse: (response: TimecodesResponse) => {
        const timecodes =
          response.results[0] &&
          response.results[0].data.timecodes
            .filter(
              (obj, index) =>
                index ===
                response.results[0].data.timecodes.findIndex((t) => t.start === obj.start || t.text === obj.text),
            )
            .map((timecode) => ({ ...timecode, start: formatDuration(Math.round(timecode.start as number)) }));

        const publicId = response.results[0]?.publicId; // Извлекаем publicId из результата

        return { timecodes, publicId }; // Возвращаем как объект с обновленной структурой
      },
    }),
    putTimecodesInPlaylistAdmin: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/${publicId}/`,
        method: 'PUT',
        body,
      }),
    }),
    patchTimecodesInPlaylistAdmin: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteTimecodesInPlaylistAdmin: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ videoPk, publicId }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/${publicId}/`,
        method: 'DELETE',
      }),
    }),

    // саммари
    getAllInPlaylistSummaries: build.query<
      GetList<AdminSummary>,
      { playlistId?: string; videoId?: string; hash?: string }
    >({
      query: ({ playlistId = '', videoId = '', hash }) => ({
        url: `${PATH}/playlist/${playlistId}/video/${videoId}/summaries/`,
        method: 'GET',
        params: { hash },
      }),
      providesTags: (_, __, { videoId }) => [{ type: 'control_panel_summaries', id: videoId }],
    }),

    updatePartialSummaryAdmin: build.mutation<
      AdminSummary,
      { playlistId?: string; videoId?: string; publicId: string; body: Partial<AdminSummary> }
    >({
      query: ({ playlistId, videoId, publicId, body }) => ({
        url: `${PATH}/playlist/${playlistId}/video/${videoId}/summaries/${publicId}/`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (_, __, { videoId }) => [{ type: 'control_panel_summaries', id: videoId }],
    }),

    // deleteSummaryAdmin: build.mutation<void, { playlistId?: string; videoId?: string; publicId: string }>({
    //   query: ({ publicId, playlistId, videoId }) => ({
    //     url: `${PATH}/playlist/${playlistId}/video/${videoId}/summaries/${publicId}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: (_, __, { videoId }) => [{ type: 'control_panel_summaries', id: videoId }],
    // }),

    // транскрипты
    getTranscriptsAdmin: build.query<GetList<Transcript>, BaseAdminRequest>({
      query: ({ videoPk }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/`,
        method: 'GET',
      }),
    }),

    getOneTranscriptAdmin: build.query<Transcript, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ publicId, videoPk }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'GET',
      }),
    }),
    putTranscriptsAdmin: build.mutation<Transcript, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'PUT',
        body,
      }),
    }),
    patchTranscriptsAdmin: build.mutation<Transcript, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteTranscriptsAdmin: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ videoPk, publicId }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetTimecodesAdminQuery,
  useLazyGetTimecodesAdminQuery,
  useGetTranscriptsAdminQuery,
  useGetOneTranscriptAdminQuery,
  usePutTranscriptsAdminMutation,
  usePatchTranscriptsAdminMutation,
  useDeleteTranscriptsAdminMutation,
  useUpdatePartialSummaryAdminMutation,
} = adminAPI;
