import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const Wrapper = styled(motion.div)`
  position: relative;
  width: fit-content !important;
  margin: 0 auto;
`;
export const WrapperSide = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, ${theme.colors.text.purple_500}, ${theme.colors.pink.pink_500});
  opacity: 0.5;
  border-radius: 8px;
  filter: blur(24px);

  transition-duration: 150ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${Wrapper}:hover & {
    opacity: 0.75;
  }
`;

export const Button = styled.button`
  position: relative;
  background: linear-gradient(to right, ${theme.colors.text.purple_600}, ${theme.colors.pink.pink_500});
  border: none;
  border-radius: 8px;
  padding: 24px 32px;
  height: 56px;
  color: ${theme.colors.white.white_100};
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  cursor: pointer;
  display: inline-flex;

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  :hover {
    background: linear-gradient(to right, ${theme.colors.text.purple_700}, ${theme.colors.pink.pink_600});
    scale: 1.02;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  @media screen and (min-width: 768px) {
    padding: 28px 40px;
  }
`;
