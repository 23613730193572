import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';

type PropsType = {
  isFixed?: boolean;
  isMobile: boolean;
  isSearch?: boolean;
  isIframe?: boolean;
  iframeTheme?: IframeThemeType;
};

export const Wrapper = styled.div<PropsType>`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 20px;

  @media screen and (max-width: 768px) {
    gap: 0;
  }

  ////!!!Modal open to correct view
  ${({ isFixed, isMobile }) => {
    if (isFixed) {
      if (isMobile) {
        return css`
          overflow-y: hidden;
          padding-right: 0;
          position: fixed;
          left: 0;
          right: 0;
          /// отключение шивтинга на тач устройствах
          @media (pointer: coarse) {
            padding-right: 0;
          }
        `;
      }
      return css`
        overflow-y: hidden;
        padding-right: 10px;
        position: fixed;
        left: 0;
        right: 0;
        /// отключение шивтинга на тач устройствах
        @media (pointer: coarse) {
          padding-right: 10px;
        }
      `;
    }
  }} /* ${({ isSearch }) =>
    isSearch &&
    css`
      background: url('/images/bg/ellipse-5.png') no-repeat 0% 90%;
    `} */
  ${({ isIframe }) =>
    isIframe &&
    css`
      padding-top: 0;
      min-height: unset;
    `}
`;

export const Main = styled.main<{ iframeTheme?: IframeThemeType }>`
  position: relative;

  @media screen and (max-width: 480px) {
    padding-top: 20%;

    ${({ iframeTheme }) =>
      iframeTheme &&
      css`
        padding: 10px;
      `}
  }
`;

export const InDev = styled.div`
  background-image: url('./images/page-in-dev.png');
  width: 100%;
  height: 100vh;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;
