import { getStylesSelect } from './consts';

import { ChangeEvent, DragEventHandler, useRef, useState } from 'react';

import axios, { type AxiosProgressEvent } from 'axios';
import { flushSync } from 'react-dom';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

import {
  playlistsAPI,
  useCreateVideoMutation,
  useGetServicesQuery,
  useLazyGetDurationVideoQuery,
  useLazyGetLinkUploadVideoFileQuery,
} from '@/api';
import { useActions } from '@/hooks/useActions';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { useAppSelector } from '@/hooks/useStore';
import { combinedRegex, rutubeRegex, vkRegex, vkVideoRegex, youtubeRegex } from '@/utils';
import { removeQueryParams } from '@/utils/deleteSearchParamFromURL';

export const useAddVideo = () => {
  const { t } = useTranslation('modal');

  const [videoLinkValue, setVideoLinkValue] = useState<string>('');
  const [selectedPlaylist, setSelectedPlaylist] = useState('');

  const [currentStep, setCurrentStep] = useState<'input' | 'uploading' | 'select-tariff'>('input');
  const [isLinkError, setIsLinkError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [incorrectVideoFormat, setIncorrectVideoFormat] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [validLink, setValidLink] = useState<string | null>(null);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState<number>(0);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const abortUploadRef = useRef<AbortController | null>(null);

  const [isCommercial] = useAppSelector((state) => [state.user.isCommercial]);
  const { showNotification, closeModal } = useActions();

  const isTablet = useMatchMedia('(max-width: 768px)');

  const { data: playlists } = playlistsAPI.useGetMyPlaylistsQuery({});
  const [addVideoToPlaylist] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [createMovie, result] = useCreateVideoMutation();

  const [getDuration] = useLazyGetDurationVideoQuery();

  const { data: services = [] } = useGetServicesQuery();

  const [getLinkUploadVideoFile] = useLazyGetLinkUploadVideoFileQuery();

  const selectedPlaylistDetails = playlists?.results.find((playlist) => playlist.publicId === selectedPlaylist);

  const allowedFormats: { [key: string]: string } = {
    mp4: '.mp4',
    // avi: '.avi',
    // mkv: '.mkv',
    // webm: '.webm',
    // mov: '.mov',
  };

  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const simulateUpload = () => {
    setProgress(0);
    setCurrentStep('uploading');

    const animate = (time: number) => {
      if (previousTimeRef?.current != undefined) {
        const deltaTime = time - previousTimeRef?.current;
        setProgress((prevCount) => {
          if (Math.round(prevCount) < 100) {
            return (prevCount + deltaTime * 0.01) % 100;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          cancelAnimationFrame(requestRef.current);
          return duration ? 100 : 98;
        });
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
  };

  const uploadFile = async ({
    presignedUrl,
    selectedFile,
    userId,
  }: {
    presignedUrl: string;
    selectedFile: File;
    userId: string;
  }) => {
    try {
      setProgress(0);

      // setIsAborted(false);

      const controller = new AbortController();

      abortUploadRef!.current = controller;

      await axios.put(presignedUrl, selectedFile, {
        headers: {
          'x-amz-meta-userId': userId,
        },
        signal: controller.signal,

          onUploadProgress: (event: AxiosProgressEvent) => {
            if (event.total) {
              const percentCompleted = Math.round((event.loaded * 100) / event.total);

              console.log(percentCompleted);

              if (percentCompleted < 92) {
                setProgress(percentCompleted);
              }

              if (percentCompleted === 100) {
                setCurrentStep('select-tariff');
                setProgress(100);
                const curfileInputRef = fileInputRef.current;
                if (curfileInputRef) {
                  curfileInputRef.value = '';
                }
              }
            }
          },
        })
        .then(() => {
          setCurrentStep('select-tariff');
        })
        .catch(() => {
          setProgress(0);
          setCurrentStep('input');
          showNotification({
            text: 'Загрузка файла была отменена',
            severity: 'error',
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload canceled:', error.message);
      } else {
        setCurrentStep('input');
        console.error('Upload failed:', error);
      }
      // showNotification({
      //   text: t('error', { ns: 'error' }),
      //   severity: 'error',
      // });
      setProgress(0);
      setIsError(true);
    }
  };

  const closeHandler = () => {
    currentStep === 'uploading' && isUploadingFile && abortUploadRef.current?.abort();

    setCurrentStep('input');
    setVideoLinkValue('');
    setIsUploadingFile(false);
    setSelectedPlaylist('');
    setIsLinkError(false);
    setIsError(false);
    setIncorrectVideoFormat(false);
    setSelectedFile(null);
    setProgress(0);
    closeModal();
  };

  const saveVideo = async (servicePublicId: string) => {
    try {
      const response = await createMovie({
        originLink: videoLinkValue || validLink || '',
        servicePublicId,
      }).unwrap();

      if (selectedPlaylist) {
        await addVideoToPlaylist({
          playlistId: selectedPlaylist,
          videos: [
            {
              videoPublicId: response.publicId,
              isAiSuggested: false,
            },
          ],
        }).unwrap();

        ReactGA.event({ category: 'files', action: 'add_video_to_library' });
      }

      showNotification({
        text: t('add_video', { ns: 'success' }),
        severity: 'success',
        // TODO: redirect to video in playlist
        link: `/videos/watch/${response.publicId}?t=0`,
      });
      ReactGA.event({ category: 'video', action: 'add_video' });

      closeHandler();
    } catch (e) {
      showNotification({
        text: t('error', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const addVideo = async () => {
    try {
      setProgress(0);
      setCurrentStep('uploading');
      if (videoLinkValue.length) {
        simulateUpload();
        const data = await getDuration({ url: videoLinkValue }).unwrap();

        setProgress(100);
        setDuration(data);
        flushSync(() => {
          setCurrentStep('select-tariff');
        });
      }

      if (selectedFile && videoLinkValue === '') {
        setIsUploadingFile(true);
        const fileType = selectedFile.type.replace('video/', '');
        const format = allowedFormats[fileType] || '';

        const fileName = selectedFile.name.replace(/\.(mp4|avi|mkv|webm|mov)$/, '');

        const formData = new FormData();
        formData.append('file', selectedFile);

        const linkData = await getLinkUploadVideoFile({ file_name: fileName, file_type: format }).unwrap();

        const validLink = removeQueryParams(linkData.presignedUrl);

        await uploadFile({ presignedUrl: linkData.presignedUrl, selectedFile, userId: linkData['x-amz-meta-userId'] });

        setValidLink(validLink);
      }
    } catch (error) {
      setIsError(true);
      showNotification({
        text: t('error', { ns: 'error' }),
        severity: 'error',
      });
      setCurrentStep('input');
    } finally {
      setIsUploadingFile(false);
    }
  };

  const videoLinkHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setVideoLinkValue(trimmedValue);
    setIsError(false);
    setIsLinkError(false);

    const isYouTube = youtubeRegex.test(trimmedValue);
    const isVK = vkRegex.test(trimmedValue);
    const isVKVideo = vkVideoRegex.test(trimmedValue);
    const isValidRutube = rutubeRegex.test(trimmedValue);
    // const isKinescope = kinescopeRegex.test(trimmedValue);
    const isValidResourceLink = combinedRegex.test(trimmedValue);

    if (isValidResourceLink) {
      if (!isYouTube && !isVK && !isVKVideo && !isValidRutube) {
        setIsError(true);
        setIsLinkError(false);
      }
    } else {
      setIsLinkError(true);
      setIsError(false);
    }
  };

  const handleFilePrompt = () => {
    if (videoLinkValue === '') {
      fileInputRef.current?.click();
    }
  };

  const validateFile = (file: File) => {
    const maxFileSize = 5 * 1024 * 1024 * 1024;

    if (
      file.size <= maxFileSize &&
      ['video/mp4', 'video/avi', 'video/mkv', 'video/webm', 'video/mov'].includes(file.type)
    ) {
      setIncorrectVideoFormat(false);
      setSelectedFile(file);
    } else {
      setIncorrectVideoFormat(true);
      // setSelectedFile(null);
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const file = event.dataTransfer?.files[0];
    if (file) {
      validateFile(file);
    }
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  const handleFileUpload = (file: File) => {
    const videoElement = document.createElement('video');

    //получение длины загруженного видео
    videoElement.src = URL.createObjectURL(file);

    videoElement.addEventListener('loadedmetadata', () => {
      const duration = Math.ceil(videoElement.duration / 60);
      setDuration(duration);
      URL.revokeObjectURL(videoElement.src);
    });
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log(file);
    if (file) {
      handleFileUpload(file);
      validateFile(file);
      e.target.value = '';
      setSelectedFile(file);
    }
  };

  const deleteVideo = () => {
    setSelectedFile(null);
  };

  const options =
    playlists?.results.map((playlist) => ({
      value: playlist.publicId,
      label: playlist.title,
      isSelectedOption: selectedPlaylist === playlist.publicId,
    })) || [];

  const stylesSelect = getStylesSelect(options);

  // const handleCancelUpload = () => {
  //   if (abortController) {
  //     abortController.abort();
  //     setIsSuccess(false);
  //     setIsAborted(true);
  //     setSelectedFile(null);
  //     setPresignedUrl('');
  //     setProgress(0);
  //   }
  // };

  return {
    videoLinkValue,
    selectedPlaylist,
    isLinkError,
    isError,
    selectedFile,
    isLoading: isUploadingFile,
    videoLinkHandler,
    addVideo,
    closeHandler,
    setSelectedPlaylist,
    setSelectedFile,
    deleteVideo,
    handleDrop,
    handleDragOver,
    onFileChange,
    handleFilePrompt,
    playlists,
    t,
    setVideoLinkValue,
    fileInputRef,
    incorrectVideoFormat,
    isTablet,
    result,
    stylesSelect,
    options,
    isCommercial,
    setIsError,
    setShowInfo,
    showInfo,
    services,
    currentStep,
    setCurrentStep,
    duration,
    selectedPlaylistDetails,
    progress,
    saveVideo,
  };
};
