import { CardsList, theme } from '../../styles';
import { ButtonText, DescriptionText, StatText, TitleH3 } from '../../styles/components';

import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';

export const Wrapper = styled.div`
  padding: 15px 0 0 0;
`;

export const BackLink = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  margin-left: 8px;
`;

export const BackLinkText = styled(ButtonText)`
  width: max-content;
`;

export const Description = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  margin-top: 35px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  height: auto;

  @media (max-width: 620px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 1024px) {
    /* height: 240px; */
    ${({ iframeTheme, isIframe }) =>
      isIframe &&
      iframeTheme &&
      css`
        gap: 5px;
        margin-top: 10px;
      `}
  }
  @media (max-width: 768px) {
    /* height: 180px; */
  }
  @media (max-width: 620px) {
    height: auto;
  }
`;

export const DescriptionImageWrapper = styled.div`
  position: relative;
`;

export const StatusInfo = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2.6666667461395264px);
`;

export const DescriptionImage = styled.img`
  width: 372px;
  /* height: 280px; */
  height: auto;
  background: linear-gradient(180deg, rgba(23, 8, 123, 0) 50%, rgba(23, 8, 123, 0.6) 100%);
  border-radius: 20px;

  align-self: flex-start;

  @media (max-width: 1024px) {
    width: 287px;
    /* height: 240px; */
  }
  @media (max-width: 768px) {
    width: 209px;
    /* height: 180px; */
  }
  @media (max-width: 620px) {
    width: 100%;
    height: auto;
  }
`;
export const DescriptionInner = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  gap: 10px 28px;
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

export const DescriptionMenuWrapper = styled.div`
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: center;
`;

export const Title = styled(TitleH3)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  max-width: 752px;
  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme &&
    css`
      margin-top: 10px;
    `}
`;
export const Text = styled(DescriptionText)<{
  isCollapsed?: boolean;
  iframeTheme?: IframeThemeType;
  isIframe?: boolean;
}>`
  color: ${theme.colors.white.white_80};

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          height: max-content;
          /* overflow: hidden; */
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        `
      : css`
          ::-webkit-scrollbar {
            width: 10px; /* ширина scrollbar */
          }

          ::-webkit-scrollbar-track {
            background: transparent; /* цвет дорожки */
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 20px; /* закругления плашки */
            background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
          }
        `}

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;
export const TextWrapper = styled.div<{ isCollapsed?: boolean; iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  color: ${theme.colors.white.white_80};

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          /* height: 40px; */
          /* overflow: hidden; */
          /* display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical;*/
        `
      : css`
          max-height: 200px;
          overflow-y: scroll;

          ::-webkit-scrollbar {
            width: 10px; /* ширина scrollbar */
          }

          ::-webkit-scrollbar-track {
            background: transparent; /* цвет дорожки */
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 20px; /* закругления плашки */
            background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
          }
        `}

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const StatisticsPanel = styled.div`
  margin: 41px 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const StatisticsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StatisticsPanelItem = styled.li`
  padding: 9px 12px;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  user-select: none;
  text-transform: lowercase;
`;

export const StatisticsPanelItemText = styled(StatText)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
  }
`;

// export const VideoList = styled.div`
//   width: 100%;
//   display: grid;
//   flex-direction: row;
//   grid-template-columns: repeat(4, 1fr);
//   gap: 20px;
//   margin-bottom: 80px;
// `;

export const VideoListWrapper = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  position: relative;
  min-height: 300px;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme &&
    css`
      margin-top: 20px;
    `}
`;

export const CardListPlaylist = styled(CardsList)``;
