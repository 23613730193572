import styled, { css } from 'styled-components';
import MyInput from '@/components/Shared/MyInput/MyInput';
import { IframeThemeType } from '@/types/iframeTypes';

export const StyledInputV2 = styled(MyInput)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  & input {
    width: 100%;
    background-color: #1a1145;
    border: 1px solid rgb(168 85 247 / 0.3);
    border-radius: 0.75rem;
    padding: 14px 16px 14px 48px;
    font-size: 16px;
    color: #a78bfa;
    transition: all 300ms;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    ::placeholder {
      color: #a78bfa;
    }

    :focus,
    :hover {
      outline: none;
      border: 1px solid #a855f7;
      box-shadow: 0 20px 25px -5px rgba(168, 85, 247, 0.1), 0 8px 10px -6px rgba(168, 85, 247, 0.1);
    }

    ${({ iframeTheme, isIframe }) =>
      isIframe &&
      iframeTheme === IframeThemeType.light &&
      css`
        background-color: transparent;
        color: rgba(26, 17, 69, 0.7);

        ::placeholder {
          color: rgba(26, 17, 69, 0.6);
        }
      `}
  }
`;
