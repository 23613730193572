import styled from 'styled-components';

import { theme } from '@/styles';

export const HeaderImage = styled.img`
  width: 100%;
  max-width: 355px;
  margin-bottom: 30px;
  margin-top: 80px;

  @media (min-width: 576px) {
  }

  @media (min-width: 1100px) {
    width: auto;
  }
`;

export const ImageSlide = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const TextContent = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 130%;
  color: ${theme.colors.label.label_light};

  @media (min-width: 576px) {
    margin: 0;
  }

  @media (min-width: 1100px) {
    font-size: 26px;
  }
`;

export const PositionImg = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    width: 25%;
    position: absolute;
    top: 43%;
    left: 42%;
  }

  @media (min-width: 1200px) {
    top: 63%;
    //top: 31%;
    left: 39%;
    //width: 30%;
  }

  @media (min-width: 1400px) {
    top: 67%;
    left: 45%;
  }

  @media (min-width: 1600px) {
    //width: 400px;
    top: 70%;
    left: 47%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 82px;
  position: relative;
`;

export const TextFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px !important;
  max-width: 50%;

  @media (max-width: 768px) {
    width: 100% !important;
    max-width: unset;
  }
`;
