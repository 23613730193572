import {
  BalanceBlock,
  BalanceCount,
  BalanceInfoBlockWrapper,
  BalanceWrapper,
  SecondaryTextBalance,
  SecondaryTextButton,
  TextBalance,
  TextPay,
} from './MyBalanceBlock.styled';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Shared/Buttons/Button/Button';

export const MyBalanceBlock = ({
  countBalance,
  estimatedCost = 0,
  canProcessVideo = false,
  showBalanceStatus,
  onClose,
}: {
  countBalance: number;
  estimatedCost?: number;
  canProcessVideo?: boolean;
  showBalanceStatus: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation('modal');
  const navigate = useNavigate();

  const addSumToBalance = () => {
    onClose();
    navigate('/balance');
  };

  return (
    <BalanceWrapper>
      <SecondaryTextBalance>{t('my_balance')}</SecondaryTextBalance>
      <BalanceBlock>
        <BalanceCount>
          <TextBalance>{countBalance} ₽</TextBalance>
          {showBalanceStatus && (
            <BalanceInfoBlockWrapper>
              <TextPay canProcessVideo={canProcessVideo}>
                {canProcessVideo ? 'Стоимость:' : 'Требуется'} {estimatedCost} ₽
              </TextPay>
            </BalanceInfoBlockWrapper>
          )}
        </BalanceCount>
        <Button onClick={addSumToBalance} theme="colored-default" variant="colored-default">
          <SecondaryTextButton>{t('recharge_ViTokens')}</SecondaryTextButton>
        </Button>
      </BalanceBlock>
    </BalanceWrapper>
  );
};
