import { FieldsWrapper, FormInnerStyled, SubmitButton } from './B2CForm.styled';
import { MyInput } from './MyInput';

import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useRegisterUserMutation } from '@/api';
import { useActions, useHandlingError } from '@/hooks';
import { RegisterB2CUser } from '@/types';

export const B2CForm = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const { openModal } = useActions();

  const { catchError } = useHandlingError();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    // setError,
  } = useForm<RegisterB2CUser & { agreement: boolean }>();

  const [userRegister, { isLoading }] = useRegisterUserMutation({ fixedCacheKey: 'registerUser' });

  const onSubmit = async ({ password, email, username, notifyChanges }: RegisterB2CUser & { agreement: boolean }) => {
    try {
      await userRegister({
        firstName: 'firstName',
        lastName: 'lastName',
        surname: 'surname',
        telegram: '',
        companyProfile: 'companyProfile',
        employeeAmount: 1,
        status: 'commercial',
        password,
        email,
        username,
        notifyChanges,
      }).unwrap();

      reset();
      closeModal();

      ReactGA.gtag('event', 'sign_up', {
        method: 'site',
      });

      openModal({ active: 'verifyinfo', data: { email } });
    } catch (error) {
      catchError(error);
    }
  };

  return (
    <form name="registration_b2c" onSubmit={handleSubmit(onSubmit)}>
      <FormInnerStyled>
        <FieldsWrapper>
          <MyInput
            required
            label={'Имя пользователя'}
            {...register('username', {
              required: true,
              minLength: 3,
              maxLength: 256,
            })}
            error={errors.username}
          />
          <MyInput label={'Telegram'} {...register('telegram')} />
        </FieldsWrapper>
        <FieldsWrapper>
          <MyInput
            required
            label={'Еmail'}
            {...register('email', {
              required: true,
              minLength: 5,
              maxLength: 256,
              pattern: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
            })}
            error={errors.email}
          />
          <MyInput
            required
            label={'Пароль'}
            type="password"
            {...register('password', {
              required: true,
              minLength: 6,
              maxLength: 128,
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*\d).{6,128}$/,
                message: 'Пароль должен содержать буквы и цифры',
              },
            })}
            error={errors.password}
          />
        </FieldsWrapper>
        <div>
          <MyInput type="checkbox" label={'Подписаться на обновления'} {...register('notifyChanges')} />
          <MyInput
            type="checkbox"
            label={
              <>
                Я согласен с{' '}
                <Link
                  to="/policy/personal"
                  style={{ textDecoration: 'underline', color: '#fff' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  условиями использования
                </Link>{' '}
                и{' '}
                <Link
                  to="/policy/privacy"
                  style={{ textDecoration: 'underline', color: '#fff' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  политикой конфиденциальности
                </Link>
              </>
            }
            {...register('agreement', {
              required: true,
            })}
            error={errors.agreement}
          />
        </div>
      </FormInnerStyled>
      <SubmitButton type="submit" disabled={isLoading}>
        {t('reg', { ns: 'modal' })}
      </SubmitButton>
    </form>
  );
};
