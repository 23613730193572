import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';

import { categoriesAPI } from '@/api';
import { Main, Wrapper } from '@/App.styled';
import { Header, Container } from '@/components';
import { useAppSelector, useIframe } from '@/hooks';
import { IframeThemeType } from '@/types';
import { Footer } from '@/pages/Lending/ui/Footer';

export const MainLayout = () => {
  const [isAuth, activeModal] = useAppSelector((state) => [state.user.isAuth, state.modal.active]);
  categoriesAPI.useGetCategoriesQuery({ params: { pageSize: 14 } }, { skip: !isAuth });

  const { isIframe, iframeTheme } = useIframe();

  return (
    <Wrapper
      isFixed={Boolean(activeModal)}
      isMobile={isMobile}
      isIframe={isIframe}
      iframeTheme={iframeTheme as IframeThemeType}
    >
      {!isIframe && <Header />}
      <Main iframeTheme={iframeTheme as IframeThemeType}>
        <Container>
          <Outlet />
        </Container>
      </Main>
      {!isIframe && <Footer />}
    </Wrapper>
  );
};

export default MainLayout;
