import { InfoImage } from '../Footer.styled';

import styled, { css } from 'styled-components';

export const TargetsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99vw;

  /* background: white; */

  .targets {
    width: 300px;

    .slick-dots {
      li {
        margin: 0;
      }

      button {
        width: 15px;
      }

      button:before {
        color: #550c72;
        opacity: 1;
      }

      .slick-active {
        button:before {
          color: #5be8bc;
        }
      }
    }
  }
`;
export const Target = styled.div<{ icon: string }>`
  width: 50px;
  height: 50px;
  ${(props) => css`
    background: url(${props.icon}) center no-repeat;
  `}/* background: red; */ /* border: 2px solid black; */
`;

export const TargetsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
`;
export const TargetsText = styled.div`
  max-width: 201px;
  color: #8d7af2;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
`;

export const TargetImage = styled(InfoImage)`
  width: 48px;
  height: 48px;
  ${(props) => css`
    background: url(${props.image}) no-repeat center;
  `}
`;
