import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const BeigeItem = styled(motion.div)`
  flex-shrink: 0;
  transform: none;
`;

export const BeigeItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  white-space: nowrap;

  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.text.purple_200};

  border: 1px solid ${theme.colors.border.purple_500_20};
  border-radius: 9999px;
  padding: 6px 12px;

  background-color: rgba(37, 26, 86, 0.5);
  backdrop-filter: blur(4px);

  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  :hover {
    background-color: rgba(37, 26, 86, 0.7);
    border-color: ${theme.colors.border.purple_500_40};
    span {
      color: ${theme.colors.text.white_100};
    }

    svg {
      transform: scale(1.1);
    }
  }

  @media (min-width: 640px) {
    padding: 8px 16px;
  }

  svg {
    transition: transform 0.3s;
    width: 15px;
    height: 15px;

    @media screen and (min-width: 768px) {
      height: 20px;
      width: 20px;
    }
  }

  span {
    transition: color 0.3s;
  }
`;
