export const getStylesSelect = (
  options: {
    value: string;
    label: string;
    isSelectedOption: boolean;
  }[],
) => ({
  control: (base: any) => ({
    ...base,
    width: '100%',
    color: '#62548B',
    cursor: 'pointer',
    border: '1px solid rgba(168, 85, 247, 0.3)',
    borderRadius: '10px',
    height: '44px',
    backgroundColor: 'rgb(26 17 69)',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid rgb(168 85 247)',
    },
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: 'rgb(26 17 69)',
    border: '1px solid rgba(168, 85, 247, 0.3)',
    borderRadius: '10px',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected ? '#1a1145' : 'rgb(26 17 69)',
    color: options.length !== 0 ? '#ffffff' : '#b2b2ee',
    fontWeight: '300',
    borderRadius: '10px',
    fontSize: options.length !== 0 ? '17px' : '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: options.length !== 0 ? '#140150' : '#1a1145',
    },
  }),
  placeholder: (base: any, { isDisabled }: any) => ({
    ...base,
    color: !isDisabled ? '#ffffff' : 'rgb(160, 174, 192)',
    fontWeight: '300',
    fontSize: '15px',
    lineHeight: '24px',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#ffffff',
    fontWeight: '300',
    fontSize: '15px',
    lineHeight: '24px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    color: 'rgba(168, 85, 247, 0.3)',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: 'rgba(168, 85, 247, 0.3)',
    transition: 'color 0.2s ease',
  }),
});
