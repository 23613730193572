/* eslint-disable react/display-name */
import {
  LendingCardDescription,
  LendingCardHeader,
  LendingCardInner,
  LendingCardSide,
  LendingCardTitle,
  LendingCardWrapper,
} from './LendingCard.styled';

import { PropsWithChildren } from 'react';

import { MotionProps } from 'framer-motion';

export const LendingCard = ({ children, index, ...props }: PropsWithChildren & { index: number } & MotionProps) => {
  return (
    <LendingCardWrapper
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        duration: 0.5,
        delay: index * 0.2,
        ease: [0.21, 0.45, 0.32, 0.9],
      }}
      {...props}
    >
      <LendingCardSide />
      <LendingCardInner>{children}</LendingCardInner>
    </LendingCardWrapper>
  );
};

LendingCard.Title = ({ children, ...props }: PropsWithChildren) => (
  <LendingCardTitle {...props}>{children}</LendingCardTitle>
);
LendingCard.Description = ({ children, ...props }: PropsWithChildren) => (
  <LendingCardDescription {...props}>{children}</LendingCardDescription>
);
LendingCard.Header = ({
  children,
  iconSize = 'md',
  ...props
}: PropsWithChildren & { iconSize?: 'lg' | 'md' | 'sm' }) => (
  <LendingCardHeader iconSize={iconSize} {...props}>
    {children}
  </LendingCardHeader>
);
