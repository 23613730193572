import { Check } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import styled from 'styled-components';

import Button from '@/components/Shared/Buttons/Button/Button';

export const SuccessPaymentWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #0f0a2b, #1a1145);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const ContainerSuccessPayment = styled.div`
  width: 100%;
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
`;
export const MotionBlock = styled(motion.div)`
  background: linear-gradient(to right, #251a56, #7e3eb951);
  border-radius: 1rem;
  padding: 24px;
  text-align: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;
export const AnimationWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(128, 0, 128, 0.1), rgba(255, 192, 203, 0.1));
  border-radius: 50%;
  filter: blur(3rem);
`;

export const CheckWrapper = styled(motion.div)`
  width: 80px;
  height: 80px;
  background: linear-gradient(to right, #6b46c1, #ed64a6);
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = styled(Check)`
  width: 40px !important;
  height: 40px !important;
`;

export const Title = styled(motion.h1)`
  font-size: 2.2rem;
  line-height: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.875rem;
  }
`;

export const SumInfoBlock = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
`;

export const SumInfoBlockText = styled.span`
  font-size: 1.45rem;
  line-height: 1.75rem;
  font-weight: 600;
`;

export const Description = styled(motion.p)`
  color: #d6bcfa;
  margin-bottom: 2rem;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 380px;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const ButtonsWrapperSuccess = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ButtonSuccessDefault = styled(Button)`
  cursor: pointer;
  height: 36px;
  font-weight: normal;
  :hover {
    border-color: rgba(128, 0, 128, 1);
    background-color: rgba(128, 0, 128, 0.1);
  }

  transition: all 0.3s;

  @media (min-width: 768px) {
    width: auto;
  }
`;

export const ButtonSuccessColored = styled(ButtonSuccessDefault)`
  cursor: pointer;
  display: flex;
  gap: 2px;
  width: 178px !important;

  &:hover {
    border-color: rgba(128, 0, 128, 1);
    background-color: rgba(128, 0, 128, 0.1);
  }

  transition: all 0.3s;

  @media (max-width: 768px) {
    width: auto !important;
  }
`;

export const ArrowRightSuccess = styled(ArrowRight)`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.5rem;
  transition: transform 0.3s;

  /* Стили при наведении на родительский элемент с классом "group" */
  .group:hover & {
    transform: translateX(0.25rem);
  }
`;

export const AnimateElements = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: linear-gradient(to right, #805ad5, #d53f8c);
  transform: translate(-50%, -50%);
`;
