import styled from 'styled-components';

export const LogoStyled = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40%;

  /* outline: none; */

  @media screen and (max-width: 1280px) {
    margin-right: 18%;
  }
  //@media screen and (max-width: 725px) {
  //    margin-right: 10%;
  //}

  @media screen and (max-width: 480px) {
    width: 48px;
    height: 48px;

    & svg {
      width: 33px;
      height: 33px;
    }
  }
`;
