import styled, { css } from 'styled-components';

import { theme } from '@/styles';

export const FormWrapper = styled.div`
  position: relative;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  gap: 26px;
  align-items: flex-end;
`;

export const SubmitButton = styled.button`
  margin-top: 30px;
  display: inline-block;
  width: 100%;
  background: linear-gradient(to left, #ec4899, #9333ea);
  border: none;
  border-radius: 8px;
  padding: 0 12px;
  height: 56px;
  color: ${theme.colors.white.white_100};
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  &:hover {
    background: linear-gradient(to left, #db2777, #7e22ce);
    transition: all 0.2s;
  }
`;

export const FormInnerStyled = styled.div`
  display: grid;
  gap: 24px;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const Label = styled.label<{ required: boolean; error: boolean }>`
  color: ${theme.colors.white.white_100};
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;

  ${(props) =>
    props.required &&
    css`
      &:after {
        content: '*';
        padding: 0 2px;
      }
    `}
  ${(props) =>
    props.error &&
    css`
      color: ${theme.colors.red.red_light};
    `}
    transition: color 0.3s ease;

  @media (max-width: 1028px) {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;
