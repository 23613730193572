import { errorsRegistration } from './helpers/errorsRegistration';
import { B2CForm } from './RegistrationModal/B2CForm';
import { Header, HeaderDescription, HeaderTitle } from './RegistrationModal/RegistrationModal.styled';

import { InnerAuth, Modal } from '../Modal';

import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { useRegisterUserMutation } from '@/api';
import { useActions, useAppSearchParams } from '@/hooks';

export const Registration = () => {
  const { t } = useTranslation('error');
  const { closeModal } = useActions();
  const { deleteAppParam } = useAppSearchParams();
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>('');

  const [, { error, isLoading, isError }] = useRegisterUserMutation({ fixedCacheKey: 'registerUser' });

  const resetErrors = () => {
    setErrorAlertIsOpen(false);
    setErrorAlertMessage('');
  };

  useEffect(() => {
    const authError = error as FetchBaseQueryError;
    if (isError && authError?.data) {
      const errorMessages = errorsRegistration(authError);
      const message = errorMessages.join('\n\n');
      setErrorAlertMessage(message);
      setErrorAlertIsOpen(true);
    } else if (isLoading && errorAlertIsOpen) {
      resetErrors();
    }
  }, [error, isError, isLoading]);

  const closeHandler = () => {
    closeModal();
    deleteAppParam('popup');
  };

  return (
    <Modal modal={'registration'} onClose={closeHandler} closeButton>
      <Header>
        <HeaderTitle>{t('menu.up', { ns: 'header' })}</HeaderTitle>
        <HeaderDescription>Создайте аккаунт для доступа к полному функционалу ViSaver</HeaderDescription>
      </Header>
      <Collapse in={errorAlertIsOpen}>
        <Alert
          severity="error"
          variant="filled"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={resetErrors}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>{t('reg')}</AlertTitle>
          {errorAlertMessage}
        </Alert>
      </Collapse>
      <InnerAuth modal={'registration'}>
        <B2CForm closeModal={closeHandler} />
      </InnerAuth>
    </Modal>
  );
};

export default Registration;
