import { api } from '@/api/api';
import { BaseParams, FileParams, GetLinkUploadVideoFileResponse, GetList, VideoFile } from '@/types';

const PATH = 'video-files';

export const videoFilesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getLinkUploadVideoFile: build.query<GetLinkUploadVideoFileResponse, FileParams>({
      query: ({ file_type, file_name }) => ({
        url: `/big-size-files/presigned-url/?file_type=${file_type}&file_name=${file_name}`,
        method: 'GET',
      }),

    }),

    createVideoFile: build.mutation<VideoFile, { link: string }>({
      query: (link) => ({
        url: `${PATH}/file-s3/`,
        method: 'POST',
        body: link,
      }),
      invalidatesTags: (result) =>
        result
          ? [
            { type: 'video-files', id: result.publicId },
            { type: 'video-files', id: 'LIST' },
          ]
          : [{ type: 'video-files', id: 'LIST' }],
    }),

    getAllVideoFiles: build.query<GetList<VideoFile>, { params?: BaseParams }>({
      query: ({ params }) => ({
        url: `${PATH}/`,
        method: 'GET',
        params,
      }),

      providesTags: (data) =>
        data
          ? [
            ...data.results.map(({ publicId: id }) => ({ type: 'video-files' as const, id })),
            { type: 'video-files', id: 'LIST' },
          ]
          : [{ type: 'video-files', id: 'LIST' }],
    }),
    getOneVideoFiles: build.query<VideoFile, { public_id: string }>({
      query: ({ public_id }) => ({
        url: `${PATH}/${public_id}/`,
        method: 'GET',
      }),

      providesTags: (result, _, { public_id: id }) =>
        result ? [{ type: 'video-file' as const, id }] : [{ type: 'video-file', id: 'one' }],
    }),

    deleteVideoFile: build.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: `${PATH}/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: 'video-files' as const, id }] : [{ type: 'video-files', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateVideoFileMutation,
  useGetAllVideoFilesQuery,
  useGetOneVideoFilesQuery,
  useGetLinkUploadVideoFileQuery,
  useLazyGetLinkUploadVideoFileQuery
} = videoFilesAPI;
