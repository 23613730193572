import {
  ButtonsWrapper,
  Form,
  SuggestVideoItem,
  SuggestVideoList,
  SuggestVideoListWrapper,
} from './SuggestVideo.styled';
import { SuggestVideoCard } from './SuggestVideoCard';

import { Button } from '../../Buttons';
import FullScreenLoader from '../../FullScreenLoader/FullScreenLoader';
import { Inner, Modal, Title } from '../Modal';

import { FormEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { useActions, useHandlingError } from '@/hooks';

interface SuggestVideoProps {
  listAiSuggestedVideoPks: string[];
}

export const SuggestVideo = ({ listAiSuggestedVideoPks }: SuggestVideoProps) => {
  const { t } = useTranslation('modal');
  const { playlistId } = useParams();

  const [addVideoToPlaylist, { error: addVideoToPlaylistError }] = playlistsAPI.useAddVideoToPlaylistMutation();
  const { data: playlist } = playlistsAPI.endpoints.getPlaylistById.useQueryState(
    { id: playlistId as string },
    { skip: !playlistId },
  );

  const { catchError } = useHandlingError();

  const { openModal, showNotification, closeModal } = useActions();

  const {
    data: suggestVideos,
    isLoading,
    error,
  } = playlistsAPI.endpoints.getSuggestionVideos.useQueryState({
    publicId: playlistId as string,
    previouslySuggestedVideos: listAiSuggestedVideoPks,
  });

  const closeHandler = () => {
    openModal({ active: 'AddPlayListVideo' });
  };

  useEffect(() => {
    if (error || addVideoToPlaylistError) {
      catchError(error || addVideoToPlaylistError);
      closeHandler();
    }
  }, [error, addVideoToPlaylistError]);

  const [videoIds, setVideoIds] = useState<string[]>(listAiSuggestedVideoPks);
  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      if (videoIds.length) {
        addVideoToPlaylist({
          playlistId: playlistId || '',
          videos: videoIds.map((id) => ({ isAiSuggested: true, videoPublicId: id })),
        }).unwrap();
        showNotification({
          text: t('cr_pl', { ns: 'success', name: playlist?.title }),
          severity: 'success',
        });
        closeModal();
      } else {
        showNotification({
          text: t('req_field', { ns: 'error' }),
          severity: 'error',
        });
      }
    } catch (e) {
      console.log(e);
      showNotification({
        text: t('req_field', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  return (
    <Modal modal={'SuggestVideo'} onClose={closeHandler} closeButton>
      <Title>{t('suggest')}</Title>
      <Inner modal={'SuggestVideo'}>
        <Form onSubmit={handleSubmit}>
          <SuggestVideoListWrapper>
            <SuggestVideoList>
              {suggestVideos &&
                suggestVideos.map((item) => (
                  <SuggestVideoItem key={item.publicId}>
                    <SuggestVideoCard
                      publicId={item.publicId}
                      thumbnailUrl={item.thumbnailUrl}
                      title={item.title}
                      setVideoIds={setVideoIds}
                      isPick={videoIds.includes(item.publicId)}
                    />
                  </SuggestVideoItem>
                ))}
            </SuggestVideoList>
          </SuggestVideoListWrapper>
          <ButtonsWrapper style={{ marginTop: '20px' }}>
            <Button theme="white" type="submit" disabled={isLoading}>
              {t('form.add', { ns: 'components' })}
            </Button>
            <Button onClick={closeHandler} type="button" theme="inline" disabled={isLoading}>
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          {isLoading && <FullScreenLoader />}
        </Form>
      </Inner>
    </Modal>
  );
};

export default SuggestVideo;
