import Footer from './Footer/Footer';
import Header from './Header';
import { SearchContainer, SearchMain, SearchWrapper } from './Search.styled';

import { useMatchMedia } from '../../hooks/useMatchMedia';
import { useAppSelector } from '../../hooks/useStore';
import { Search } from '../../pages';

export const SearchLayout = () => {
  const activeModal = useAppSelector((state) => state.modal.active);
  const isMobile = useMatchMedia('(max-width: 1000px)');

  return (
    <SearchWrapper isFixed={Boolean(activeModal)} isMobile={isMobile} isSearch>
      <Header />
      <SearchMain>
        <SearchContainer>
          <Search />
        </SearchContainer>
      </SearchMain>
      {/*{!isMobile && <Socials />}*/}
      <Footer />
    </SearchWrapper>
  );
};

export default SearchLayout;
