import styled, { css } from 'styled-components';

import { FragmentTimeV2 } from '@/components/Card/VideoFragmentCard/VideoFragmentCard.styled';
import { IframeThemeType } from '@/types/iframeTypes';

export const TitleTranscript = styled(FragmentTimeV2)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-size: 20px;
  line-height: 24px;
  color: white;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: rgb(26 17 69);
    `}
`;

export const TitleTranscriptWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;
export const ButtonTranscriptWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const ButtonTranscript = styled.button<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #d8b4fe;
  background-color: #0b0330;
  border: 1px solid rgba(168, 85, 247, 0.3);
  border-radius: 6px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: color 0.3s, background-color 0.3s;

  :hover {
    color: white;
    background-color: rgba(168, 85, 247, 0.2);
  }

  :focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px rgba(168, 85, 247, 0.2);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgb(228, 228, 255);
      color: rgb(26, 17, 69);

      :hover {
        color: rgb(26, 17, 69);
        background-color: rgba(168, 85, 247, 0.2);
      }
    `}
`;

export const TextBlockScroll = styled.div`
  height: 453px;
  overflow-y: scroll;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 6px; /* ширина scrollbar */
  }

  ::-webkit-scrollbar-track {
    background-color: #1a1145ff; /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: rgb(59 42 126); /* цвет плашки */
  }
`;

export const TextTranscript = styled.span<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-size: 14px;
  color: #d8b4fe;
  white-space: pre-line;
  line-height: 18px;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: #1a1145ff;
    `}
`;
