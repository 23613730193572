import { IframeThemeType } from '../../types/iframeTypes';
import { theme } from '../../styles';

const SettingsIcon = ({ iframeTheme }: { iframeTheme?: IframeThemeType }) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M11 12.7C11.7234 12.7 12.417 12.4156 12.9285 11.9092C13.44 11.4029 13.7273 10.7161 13.7273 10C13.7273 9.28393 13.44 8.59718 12.9285 8.09083C12.417 7.58448 11.7234 7.30002 11 7.30002C10.2767 7.30002 9.58302 7.58448 9.07156 8.09083C8.5601 8.59718 8.27276 9.28393 8.27276 10C8.27276 10.7161 8.5601 11.4029 9.07156 11.9092C9.58302 12.4156 10.2767 12.7 11 12.7Z"
      stroke={iframeTheme === IframeThemeType.light ? theme.colors.blue.blue_dark : '#E4E4FF'}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.00003 10.8391V9.16092C1.00003 8.16928 1.84961 7.34927 2.89908 7.34927C4.70818 7.34927 5.44781 6.12879 4.53826 4.63179C4.01852 3.77364 4.32837 2.65804 5.23791 2.16222L6.96705 1.21826C7.75665 0.770112 8.77614 1.03709 9.24591 1.79036L9.35585 1.97152C10.2554 3.46852 11.7347 3.46852 12.6442 1.97152L12.7542 1.79036C13.2239 1.03709 14.2434 0.770112 15.033 1.21826L16.7621 2.16222C17.6717 2.65804 17.9815 3.77364 17.4618 4.63179C16.5523 6.12879 17.2919 7.34927 19.101 7.34927C20.1405 7.34927 21 8.15974 21 9.16092V10.8391C21 11.8307 20.1505 12.6507 19.101 12.6507C17.2919 12.6507 16.5523 13.8712 17.4618 15.3682C17.9815 16.2359 17.6717 17.342 16.7621 17.8378L15.033 18.7817C14.2434 19.2299 13.2239 18.9629 12.7542 18.2096L12.6442 18.0285C11.7447 16.5315 10.2654 16.5315 9.35585 18.0285L9.24591 18.2096C8.77614 18.9629 7.75665 19.2299 6.96705 18.7817L5.23791 17.8378C4.80223 17.5985 4.48387 17.204 4.3527 16.741C4.22153 16.278 4.28827 15.7843 4.53826 15.3682C5.44781 13.8712 4.70818 12.6507 2.89908 12.6507C1.84961 12.6507 1.00003 11.8307 1.00003 10.8391Z"
      stroke={iframeTheme === IframeThemeType.light ? theme.colors.blue.blue_dark : '#E4E4FF'}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SettingsIcon;
