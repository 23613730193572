import { ContentTitle } from '@/pages/BalancePage/BalancePage.styled';
import Info from '@/components/SvgIcons/Info';
import { useTranslation } from 'react-i18next';
import { Container } from '@/pages/BalancePage/CalculatorTab/CalculatorTab.styled';
import { TitleDesc } from '@/pages/BalancePage/TariffsTab/TarifItem/TarifItem.styled';
import {
  ContentCardBlockHistory,
  CountTitleHistory,
  CountTitleHistoryRed,
  TitleHistory,
} from '@/pages/BalancePage/HistoryTab/HistoryTab.styled';
import { useGetOrdersQuery } from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLoader/FullScreenLoader';

export const HistoryTab = () => {
  const { t } = useTranslation('pages');
  const { data: history, isLoading } = useGetOrdersQuery();

  return (
    <Container>
      {isLoading && <FullScreenLoader />}
      <ContentTitle>
        {t('balance.history_title')}
        <Info />
      </ContentTitle>
      <div style={{ marginBottom: '23px' }}>
        <TitleDesc>{t('balance.history_info')}</TitleDesc>
      </div>

      {history &&
        history.length !== 0 &&
        history.map((item, index) => {
          return (
            <>
              {item.data.type === 'DEPOSIT' && item.data.amount > 0 && (
                <ContentCardBlockHistory
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div>
                    <TitleDesc>{item.data.created.slice(0, 10)}</TitleDesc>
                    <TitleHistory>{t('balance.buy_plus')}</TitleHistory>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CountTitleHistory>
                      +{item.data.amount} {item.data.currencySign}
                    </CountTitleHistory>
                    {/*<TitleDescGreen>+500 ViТокенов</TitleDescGreen>*/}
                  </div>
                </ContentCardBlockHistory>
              )}
              {item.data.type === 'WITHDRAWAL' && item.data.amount > 0 && (
                <ContentCardBlockHistory
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div>
                    <TitleDesc>{item.data.created.slice(0, 10)}</TitleDesc>
                    <TitleHistory>{t('balance.buy_minus')}</TitleHistory>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CountTitleHistoryRed>
                      -{item.data.amount} {item.data.currencySign}
                    </CountTitleHistoryRed>
                    {/*<TitleDescRed>-500 ViТокенов</TitleDescRed>*/}
                  </div>
                </ContentCardBlockHistory>
              )}
            </>
          );
        })}
    </Container>
  );
};
