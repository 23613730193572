import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';
import { theme } from '@/styles';
import { Link } from 'react-router-dom';

type VideoWrapperPropsType = {
  iframeTheme?: IframeThemeType;
};

export const CardList = styled.div`
  position: relative;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const VideoWrapper = styled.div<VideoWrapperPropsType>`
  width: 100%;
  /* height: 500px; */
  background-color: ${theme.colors.blue.blue_5};
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('https://catherineasquithgallery.com/uploads/posts/2021-02/1612753659_156-p-fon-gradient-goluboi-fioletovii-180.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 75px 50px rgba(11, 0, 44, 0.49);

  ${({ iframeTheme }) =>
    iframeTheme === IframeThemeType.light &&
    css`
      box-shadow: none;
    `}
`;

export const Container = styled.div<{ isIframe?: boolean }>`
  margin-left: auto;
  margin-right: auto;
  //padding: 64px 16px;
  padding: 50px 0;
  max-width: 1280px;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    padding-top: ${({ isIframe }) => (isIframe ? '0px' : '32px')};
    padding-bottom: ${({ isIframe }) => (isIframe ? '0px' : '32px')};
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const VideoCardV2Wrapper = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  max-width: 100%;

  background-color: #1a1145;
  border-radius: 0.75rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  padding: 24px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background: rgba(60, 64, 67, 0.07);
    `}
`;

export const Inner = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  background-color: #251a56;
  border-radius: 1rem;
  padding: 16px;
  min-height: 830px;

  @media (min-width: 640px) {
    padding: 24px;
  }

  @media (min-width: 768px) {
    padding: 32px;
  }

  @media (min-width: 1024px) {
    padding: 40px;
  }

  border: 1px solid rgba(168, 85, 247, 0.3);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: white !important;
    `}
`;

export const Content = styled.div`
  gap: 10px;
  display: flex;
  position: relative;

  @media (min-width: 648px) {
    gap: 28px;
  }

  @media (min-width: 1280px) {
    gap: 48px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Video = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, rgba(168, 85, 247, 0.05), transparent, transparent);
  width: 100%;
  min-width: 50%;

  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

export const StyledLink = styled(Link)`
  color: ${theme.colors.white.white_100};
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-left: 35px;
  margin-bottom: 30px;

  &:hover {
    opacity: 0.7;
  }
`;

export const VideoPlayer = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  position: relative;
  aspect-ratio: 16 / 9;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(168, 85, 247, 0.3);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: #1a1145;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background: white;
    `}
`;

export const ContentBlock = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MaterialWrapper = styled.div`
  flex: auto;
  min-height: 685px;
`;
export const ContentWrap = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  background-color: rgb(37 26 86);
  border-radius: 0.75rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  padding: 20px;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgba(60, 64, 67, 0.07);
    `}
`;

export const Title = styled.p<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
    //margin-left: 20px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;
