import { api } from '../api';

import {
  BaseParams,
  CreateVideoType,
  DurationResponseType,
  GetList,
  QuizApiResponse,
  SearchAIMovie,
  SummaryResponse,
  Timecode,
  TimecodesRequest,
  TimecodesResponse,
  Transcript,
  TranscriptCue,
  Video,
  VideoParams,
  VideoWithFragments,
} from '@/types';
import { getSearchParamFromURL } from '@/utils';

const PATH = 'videos';

const searchPATH = 'videos/search/';

export const videosAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getVideos: build.query<GetList<Video>, { params?: BaseParams & VideoParams }>({
      query: ({ params }) => ({
        url: `${PATH}/`,
        method: 'GET',
        params,
      }),

      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ publicId: id }) => ({ type: 'videos' as const, id })),
              { type: 'videos', id: 'LIST' },
            ]
          : [{ type: 'videos', id: 'LIST' }],
    }),

    getMyVideos: build.query<GetList<Video>, { params?: BaseParams & VideoParams } | void>({
      query: ({ params } = {}) => ({
        url: `${PATH}/my/`,
        method: 'GET',
        params,
      }),

      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ publicId: id }) => ({ type: 'videos' as const, id })),
              { type: 'videos', id: 'LIST' },
            ]
          : [{ type: 'videos', id: 'LIST' }],
    }),

    getMovieById: build.query<Video, { id: string }>({
      query: ({ id }) => ({
        url: `${PATH}/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'videos', id: 'ONE' }],
    }),

    getTranscriptById: build.query<Video, { publicId: string }>({
      query: ({ publicId }) => ({
        url: `transcripts/${publicId}/`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response.results[0],

      providesTags: [{ type: 'transcript' }],
    }),

    getTranscript: build.query<{ cues: TranscriptCue[]; publicId: string }, { publicId: string }>({
      query: ({ publicId }) => ({
        url: `${PATH}/${publicId}/transcripts/`,
        method: 'GET',
      }),
      transformResponse: (response: GetList<Transcript>) => {
        const transcript = response.results[0];
        return {
          cues: transcript?.data.cues || [],
          publicId: transcript?.publicId || '',
        };
      },
      providesTags: (result) => (result ? [{ type: 'transcript', id: result.publicId }] : []),
    }),

    //получение таймкодов к видео в библиотеке и в личном кабинете
    getTimecodes: build.query<Timecode[], TimecodesRequest & { hash?: string }>({
      query: ({ videoPublicId, hash }) => ({
        url: `${PATH}/${videoPublicId}/timecodes/`,
        method: 'GET',
        params: { linkHash: hash },
      }),
      transformResponse: (response: TimecodesResponse) =>
        (response.results?.[0]?.data?.timecodes ?? [])
          .filter((obj, index) => {
            return (
              index ===
              response.results?.[0]?.data?.timecodes.findIndex((t) => t.start === obj.start || t.text === obj.text)
            );
          })
          .map((timecode) => ({ ...timecode, startOffsetMs: Math.round(Number(timecode.start)) }))
          .sort((a, b) => a.startOffsetMs - b.startOffsetMs),
    }),

    //получение конспекта к видео в библиотеке и в личном кабинете
    getDocs: build.query<
      {
        htmlSummary: string;
        pdfFile: string;
        markdown: string | null;
      },
      TimecodesRequest & { hash?: string }
    >({
      query: ({ videoPublicId, hash }) => ({
        url: `${PATH}/${videoPublicId}/summaries/`,
        method: 'GET',
        params: { linkHash: hash },
      }),
      transformResponse: (response: SummaryResponse) => {
        const result = response.results[0];
        return {
          pdfFile: result.pdfFile,
          markdown: result.markdown,
          htmlSummary: result.html,
        };
      },
    }),

    //получение квизов к видео в библиотеке
    getVideoAllQuizzes: build.query<QuizApiResponse, TimecodesRequest & { hash?: string }>({
      query: ({ videoPublicId = '', hash }) => ({
        url: `${PATH}/${videoPublicId}/quizzes/`,
        method: 'GET',
        params: { linkHash: hash },
      }),
      providesTags: (_, __, { videoPublicId }) => [{ type: 'quiz', id: videoPublicId }],
      transformResponse: (response: GetList<QuizApiResponse>) => response.results[0],
    }),

    getFullSearchInVideo: build.query<VideoWithFragments[], Pick<Video, 'videoId'> & { query: string }>({
      query: ({ videoId, query }) => ({
        url: `${PATH}/${videoId}/full_search/`,
        method: 'GET',
        params: { query },
      }),

      transformResponse: (data: VideoWithFragments[]) => data.filter((video) => video.cues.length > 0),
    }),

    createVideo: build.mutation<Video, CreateVideoType>({
      query: (body) => ({
        url: `${PATH}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: 'videos', id: result.publicId }, { type: 'videos', id: 'LIST' }, 'userMe']
          : [{ type: 'videos', id: 'LIST' }, 'userMe'],
    }),

    deleteVideo: build.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: `${PATH}/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, { id }) =>
        result
          ? [{ type: 'personal_playlist' }, { type: 'videos', id }, 'private_playlist']
          : [{ type: 'personal_playlist', id: 'one' }, { type: 'videos', id: 'LIST' }, 'private_playlist'],
    }),

    getDurationVideo: build.query<number, { url: string }>({
      query: (params) => ({
        url: `${PATH}/get-duration/`,
        method: 'GET',
        params,
      }),
      transformResponse: ({ duration }: DurationResponseType) => Math.ceil(duration / 60),
    }),

    getSearchVideos: build.query<SearchAIMovie[], { search_str?: string | null; playlist_id?: string }>({
      query: ({ search_str = '', playlist_id }) => ({
        url: searchPATH,
        method: 'GET',
        params: {
          search_str,
          playlist_id,
        },
      }),
      providesTags: (result) =>
        result ? result.map(({ publicId: id }) => ({ type: 'searchInPlaylist' as const, id })) : ['searchInPlaylist'],
      transformResponse: (data: SearchAIMovie[]) => {
        return data.map((video) => ({
          ...video,
          timestamp_link: getSearchParamFromURL(video.timestamp_link, 't'),
        }));
      },
    }),
  }),
});

export const {
  useGetTimecodesQuery,
  useGetTranscriptByIdQuery,
  useGetTranscriptQuery,
  useLazyGetTimecodesQuery,
  useGetDocsQuery,
  useLazyGetDocsQuery,
  useGetVideoAllQuizzesQuery,
  useGetVideosQuery,
  useGetMyVideosQuery,
  useGetSearchVideosQuery,
  useDeleteVideoMutation,
  useLazyGetSearchVideosQuery,
  useGetMovieByIdQuery,
  useLazyGetFullSearchInVideoQuery,
  useLazyGetDurationVideoQuery,
  useCreateVideoMutation,
} = videosAPI;
