import { Limit, LimitItem, LimitList, Wrapper, StatHeader } from './StatisticPage.styled';
import StatTable from './StatTable/StatTable';

import FullScreenLoader from '../../components/Shared/FullScreenLoader/FullScreenLoader';
import { LabelText, PaginationWrapper } from '../../styles';
import { HeaderTitle, InfoTitle, InfoWrapper } from '../LibraryPage/LibraryPage.styled';

import { memo, useEffect } from 'react';

import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usersAPI } from '@/api';
import { useHandlingError, useMatchMedia, usePagination } from '@/hooks';

const limits = [10, 30, 50];

const StatisticPage = () => {
  const { catchError } = useHandlingError();
  const { t } = useTranslation('header');
  const isDesktop = useMatchMedia('(min-width: 1025px)');

  const {
    pagination: { pages, page },
    limit,
    handleChangeLimit,
    handleChangePage,
    setCount,
  } = usePagination();

  const { data, isLoading, error, isSuccess, isFetching } = usersAPI.useGetUserStatQuery({ page, limit });

  useEffect(() => {
    if (data) {
      setCount(data.count);
    }
  }, [data]);

  useEffect(() => {
    catchError(error);
  }, [error]);

  return (
    <Wrapper>
      <StatHeader>
        <HeaderTitle>{t('menu.stat')}</HeaderTitle>
      </StatHeader>
      {(isLoading || isFetching) && <FullScreenLoader />}
      {isSuccess && data.results.length > 0 && (
        <>
          <StatTable playlists={data.results} />
          <PaginationWrapper>
            {pages != 1 && (
              <Pagination
                count={pages}
                page={page}
                size={isDesktop ? 'medium' : 'small'}
                color="primary"
                onChange={handleChangePage}
              />
            )}
            {isDesktop && data.count > 10 && (
              <Limit>
                <LabelText>{t('statistics.limit', { ns: 'pages' })}</LabelText>
                <LimitList>
                  {limits.map((limitItem) => (
                    <LimitItem
                      key={limitItem}
                      active={limit === limitItem}
                      onClick={() => handleChangeLimit(limitItem)}
                    >
                      {limitItem}
                    </LimitItem>
                  ))}
                </LimitList>
              </Limit>
            )}
          </PaginationWrapper>
        </>
      )}
      {isSuccess && data.results.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_pl', { ns: 'pages' })}</InfoTitle>
        </InfoWrapper>
      )}
    </Wrapper>
  );
};

export default memo(StatisticPage);
