import { memo } from 'react';

import { useNavigate } from 'react-router-dom';

import { GoBackButton } from '@/components';

const GoBack = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/account/view');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <GoBackButton onClick={goBack} />
    </div>
  );
};

export default memo(GoBack);
