import DropDownMenu from './DropDownMenu';

import { useRef } from 'react';

import { DropDownProps, DropDownStyled } from '@/components';

const DropDown = ({ children, items, isOpen, toggleOpen, iframeTheme, ...props }: DropDownProps) => {
  const DropRef = useRef(null);

  return (
    <DropDownStyled onClick={toggleOpen} ref={DropRef} {...props}>
      {children}
      <DropDownMenu iframeTheme={iframeTheme} open={isOpen} items={items} close={toggleOpen} wrapperRef={DropRef} />
    </DropDownStyled>
  );
};

export default DropDown;
