import styled from 'styled-components';

import { FooterText, theme } from '@/styles';

export const FooterStyled = styled.footer`
  border-top: 1px solid rgb(168 85 247 / 0.2);

  margin-top: 96px;

  & > div {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;
// export const Inner = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 30px 0;
//   gap: 10px;
// `;

export const LinksWrapper = styled.div`
  & a {
    color: white;
    opacity: 0.8;
    text-align: end;
  }

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const GeneralInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TitleNewH3 = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: ${theme.colors.text.white_100};
`;

export const List = styled.ul`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  a {
    color: ${theme.colors.text.purple_300};
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    color: ${theme.colors.text.purple_300};
  }
`;

export const ListItem = styled.li``;

export const Text = styled.p`
  color: ${theme.colors.text.purple_300};
  user-select: none;
  font-size: 16px;
  line-height: 24px;
`;

export const IconsList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const IconsListItem = styled.li``;

export const Copy = styled(FooterText)`
  color: ${theme.colors.text.purple_300};
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  flex-shrink: 0;
`;

export const InfoWrapper = styled.div`
  border-top: 1px solid rgb(168 85 247 / 0.2);
  margin-top: 48px;
  padding-top: 32px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const InfoIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  flex-shrink: 0;

  img {
    opacity: 0.7;
    :hover {
      opacity: 1;
    }
  }
`;
