import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { Category, CreatePlaylistForm, CreatePlaylistType, PrivacyType } from '@/types';
import type { SingleValue } from 'react-select';

import { useActions, useAppSelector, useHandlingError } from '@/hooks';

const defaultFormValues = (categories: Category[]) =>
  ({
    title: '',
    description: '',
    category: categories[11]?.publicId,
    privacyType: 'private',
  } satisfies CreatePlaylistForm);

const initialErrorAlert = {
  open: false,
  message: '',
};

export const usePlaylist = (userPlaylist?: CreatePlaylistType) => {
  const { t } = useTranslation('options');

  const [categories] = useAppSelector((state) => [state.categories.categories]);

  const { catchError } = useHandlingError();

  const { closeModal, showNotification } = useActions();

  const [errorAlert, setErrorAlert] = useState(initialErrorAlert);

  const categoryOptions: { value: string; label: string }[] = useMemo(
    () =>
      categories.map((category) => ({
        value: category.publicId,
        label: t(category.name),
      })),
    [categories, t],
  );

  const privacyOptions: { value: PrivacyType; label: string }[] = useMemo(
    () => [
      { value: 'public', label: t('public') },
      { value: 'private', label: t('private') },
    ],
    [t],
  );

  const [playlist, setPlaylist] = useState<CreatePlaylistForm>(userPlaylist ?? defaultFormValues(categories));

  const descriptionInputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPlaylist((prev) => ({ ...prev, description: e.target.value }));
    setErrorAlert(initialErrorAlert);
  };

  const titleInputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPlaylist((prev) => ({ ...prev, title: e.target.value }));
    setErrorAlert(initialErrorAlert);
  };

  const selectCategoryHandler = (option: SingleValue<{ value: string; label: string }>) => {
    if (option) {
      setPlaylist((prev) => ({ ...prev, category: option.value }));

      setErrorAlert(initialErrorAlert);
    }
  };
  const selectAccessHandler = (option: SingleValue<{ value: PrivacyType; label: string }>) => {
    if (option) {
      setPlaylist((prev) => ({ ...prev, privacyType: option.value }));

      setErrorAlert(initialErrorAlert);
    }
  };

  const errorCreatePlaylist = (error: any) => {
    setErrorAlert({
      open: true,
      message: t('field_uniq', { ns: 'error' }) || error.data?.title,
    });
  };

  const resetError = () => {
    setErrorAlert(initialErrorAlert);
  };

  const closeHandler = (userPlaylist?: CreatePlaylistType) => {
    closeModal();
    setPlaylist(userPlaylist ?? defaultFormValues(categories));
    resetError();
  };

  return {
    errorAlert,
    setErrorAlert,
    playlist,
    setPlaylist,
    categories,
    categoryOptions,
    privacyOptions,
    descriptionInputHandler,
    titleInputHandler,
    selectCategoryHandler,
    selectAccessHandler,
    closeHandler,
    resetError,
    errorCreatePlaylist,
    showNotification,
    catchError,
  };
};
