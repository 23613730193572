import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const PartnerStyled = styled.section`
  padding: 96px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0px;
  }
`;

export const PartnerWrapper = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: column;
`;

export const TitleWrapper = styled(motion.div)`
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 48px;
  color: transparent;
  font-weight: 700;
  background-clip: text;
  user-select: 'none';
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const CardsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: #251a56;
  border-radius: 16px;
  padding: 32px;
  text-align: start;

  height: 100%;

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  border: 1px solid ${theme.colors.border.purple_500_20};
  &:hover {
    border-color: ${theme.colors.border.purple_500_40};
  }
`;

export const CardLogoWrapper = styled.div`
  margin-bottom: 24px;
`;

export const CardTitle = styled.h3`
  color: ${theme.colors.text.white_100};
  font-size: 24px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 12px;

  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${Card}:hover & {
    color: ${theme.colors.text.purple_200};
  }
`;
export const CardDescription = styled.h3`
  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.text.purple_300};
  margin-bottom: 32px;
`;

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;
`;

export const InnerCardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const InnerCard = styled.div`
  text-align: center;
  background-color: ${theme.colors.text.purple_500_5};
  border-radius: 16px;
  border: 1px solid ${theme.colors.border.purple_500_10};

  padding: 12px;
`;
export const InnerValue = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.colors.text.purple_200};
  margin-bottom: 4px;
`;

export const InnerLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.text.purple_300};
`;
