import AvatarWithMenu from './AvatarMenu/AvatarWithMenu';
import { BalanceButton, BalanceText, ButtonsWrapper, HeaderStyled, Inner } from './Header.styled';

import { Button, Container } from '../Shared';
import PlusBig from '../SvgIcons/PlusBigIcon';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { usersAPI } from '@/api';
import { useActions, useAppSelector, useMatchMedia } from '@/hooks';
import { ModalType } from '@/store/slices/modalsSlice';
import logo from '@/pages/Lending/ui/Header/logo.svg';
import visaver_logo from '@/pages/Lending/ui/Header/visaver.png';

const Header = () => {
  const { openModal } = useActions();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  // const [searchInputMobileIsOpen, setSearchInputMobileIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation('header');
  const location = useLocation();
  const balancePage = location.pathname.includes('balance');
  const { data: user } = usersAPI.useGetUserMeQuery();
  const isMobile = useMatchMedia('(max-width: 768px)');

  // const inputMobileCloseHandler = () => {
  //   setSearchInputMobileIsOpen(false);
  //   resetSearch();
  // };

  const openModalHandler = (modal: ModalType) => {
    if (isAuth) {
      openModal({ active: modal });
    } else {
      openModal({ active: 'login' });
    }
  };

  return (
    <HeaderStyled>
      <Container>
        <Inner balancePage={balancePage}>
          <Link to="/" style={{ flexShrink: 0, marginRight: '24px', alignItems: 'center', display: 'flex' }}>
            <img src={logo} alt="ViSaver Logo" width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
            <img src={visaver_logo} alt="ViSaver" width={150} />
          </Link>

          {/*<InputWrapper isOpen={searchInputMobileIsOpen}>*/}
          {/*  <SearchInput />*/}
          {/*  <Button variant="withIcon" onClick={inputMobileCloseHandler} theme="transparent">*/}
          {/*    <CrossIcon />*/}
          {/*  </Button>*/}
          {/*</InputWrapper>*/}
          <ButtonsWrapper>
            {/*<SearchButton onClick={() => setSearchInputMobileIsOpen(true)} />*/}
            {isAuth && user && (
              <BalanceButton onClick={() => navigate('/balance')}>
                {/*<ViTokenIcon />*/}
                <BalanceText>{user.balance} ₽</BalanceText>
              </BalanceButton>
            )}
            <Button theme="black" onClick={() => openModalHandler('AddVideo')}>
              {t('addVideo')}
            </Button>
            <Button theme="black" onClick={() => openModalHandler('AddPlayList')}>
              {t('createPlaylist')}
            </Button>
            <Button variant="withIcon" theme="transparent" onClick={() => openModalHandler('AddMobile')}>
              <PlusBig />
            </Button>
          </ButtonsWrapper>
          <AvatarWithMenu />
        </Inner>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
