import { Block, Content, FragmentTimeV2, FrameImage, Time, TitleFragment } from './VideoFragmentCard.styled';

import { Clock } from '@/components/SvgIcons/Clock';
import { Cue, IframeThemeType } from '@/types';
import { getImageUrl, secondsToTime } from '@/utils';

export interface VideoFragmentCardV2Props {
  fragment: Cue;
  goToTime?: (time: number) => void;
  videoPreview?: string | null;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const VideoFragmentCard = ({
  fragment: { content, startsFrom, image },
  goToTime,
  isIframe,
  iframeTheme,
  videoPreview,
}: VideoFragmentCardV2Props) => {
  const goToHandler = () => {
    goToTime?.(startsFrom);
  };

  const getBackgroundImage = () => {
    if (image) {
      return getImageUrl(image);
    }
    return videoPreview ? getImageUrl(videoPreview) : '/images/playlist_mock.png';
  };

  return (
    <Content isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={goToHandler}>
      <Block>
        <Time>
          <Clock color={isIframe && iframeTheme === IframeThemeType.light ? 'rgb(37 26 86)' : 'rgb(168 85 247)'} />
          <FragmentTimeV2 isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {secondsToTime(startsFrom)}
          </FragmentTimeV2>
        </Time>
        {/*<ShareIconWrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
        {/*  <Share color={isIframe && iframeTheme === IframeThemeType.light ? 'rgb(37 26 86)' : 'rgb(168 85 247)'} />*/}
        {/*</ShareIconWrapper>*/}
      </Block>
      <div>
        <FrameImage src={getBackgroundImage() ?? ''} />
        <TitleFragment
          isIframe={isIframe}
          iframeTheme={iframeTheme as IframeThemeType}
          dangerouslySetInnerHTML={{ __html: `${content.slice(0, 110)}...` }}
        />
      </div>
    </Content>
  );
};
