import styled from 'styled-components';

import { theme } from '@/styles';

export const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  :focus-visible {
    outline: none;
  }
`;

export const Content = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.modal.purple_500_30};
  background-color: #1a1145;

  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100vw;
  max-width: 100%;

  @media (min-width: 768px) {
    width: 800px;
    border-radius: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;

  gap: 16px;

  padding-bottom: 16px;

  @media (min-width: 640px) {
    text-align: start;
  }
`;

export const HeaderTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.6px;
  color: ${theme.colors.text.white_100};
`;
export const HeaderDescription = styled.p`
  color: ${theme.colors.text.purple_200};
`;

export const CloseButton = styled.button`
  position: absolute;

  border-radius: 50%;
  top: 16px;
  right: 16px;

  opacity: 0.7;

  color: ${theme.colors.text.white_100};

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  :hover {
    opacity: 1;
  }

  :focus,
  :focus-visible {
    border: 2px solid ${theme.colors.border.purple_500};
    outline: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const VariantButton = styled.button`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  padding: 32px;
  background-color: #251a56;

  border: 1px solid ${theme.colors.border.purple_500_30};

  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  text-align: start;

  &:hover {
    border-color: ${theme.colors.border.purple_500};
    background-image: linear-gradient(to right, ${theme.colors.text.purple_600_10}, ${theme.colors.pink.pink_500_10});
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  gap: 24px;
  padding: 32px 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const LendingCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LendingCardIconWrapper = styled.div`
  width: 48px;
  width: 48px;
  margin-bottom: 24px;

  border-radius: 12px;
  border: 1px solid ${theme.colors.border.purple_500_10};

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  svg {
    width: 24px;
    height: 24px;
    color: ${theme.colors.text.purple_400};
  }
`;

export const LendingCardTitle = styled.h3`
  font-size: 20px;
  line-height: 28px;

  margin-bottom: 12px;
  color: ${theme.colors.text.white_90};
  font-weight: 600;
  user-select: 'none';
  margin-bottom: 16px;
  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  ${LendingCardWrapper}:hover & {
    color: ${theme.colors.text.white_100};
  }
`;

export const LendingCardDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.text.purple_300};

  transition-duration: 300ms;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  /* ${LendingCardWrapper}:hover & {
    color: ${theme.colors.text.white_100};
  } */
`;
