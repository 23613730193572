import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { ContentTitle } from '@/pages/BalancePage/BalancePage.styled';
import { theme } from '@/styles';

export const TitleDesc = styled.span`
  font-size: 14px;
  color: ${theme.colors.modal.text.purple};
`;

export const CountTextPrice = styled.span`
  font-size: 18px;
  color: ${theme.colors.modal.text.purple};
  margin-bottom: 20px;
`;

export const ContentTitleCard = styled(ContentTitle)`
  font-size: 20px;
  line-height: 28px;
  color: white;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

export const BuyBlockTitle = styled(ContentTitleCard)`
  font-size: 24px;
  color: white;
  margin-bottom: 0;
`;

export const ContentCardBlock = styled(motion.div)`
  background-color: ${theme.colors.modal.purple_800};
  border-radius: 12px;
  padding: 24px;
  border: 1px solid ${theme.colors.modal.purple_300};
  margin-top: 16px;

  :hover {
    border-color: ${theme.colors.modal.purple_500};
  }
`;

export const ContentTitleBlock = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
`;

export const BuyBlock = styled(motion.div)`
  background-color: rgba(26, 17, 69, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Unlimited = styled.div`
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(to right, #a855f7, #ec4899);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transition: all 0.5s;

  .group:hover & {
    background: linear-gradient(to right, #ec4899, #a855f7);
  }
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export const CountText = styled(ContentTitleCard)`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  flex-shrink: 0;
`;

export const BonusValue = styled.div`
  background: linear-gradient(to right, #22c55e, #16a34a);
  color: white;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 9999px;
  cursor: pointer;
`;

export const PriceLine = styled.div`
  background-color: #1a1145;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;

export const CountTitle = styled.p`
  font-size: 16px;
  color: ${theme.colors.modal.text.purple};

  margin-bottom: 1.5rem;

  flex-shrink: 0;
`;

export const PaymentWrapper = styled.div`
  padding: 20px;
  background-color: #251a56;
  border-radius: 12px;
`;

export const Shadow = styled.div``;

export const SumBlock = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 32px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 6%;
    padding-right: 6%;
  }
`;

export const TarifDetailWrap = styled(motion.li)`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  font-size: 15px;
  line-height: 1.625;
  color: #e9d5ff;
`;

export const PromocodeWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Promocode = styled.div`
  flex: 1;
  position: relative;
`;

export const PromocodeInput = styled.input`
  background-color: #1a1145;
  border: 1px solid rgb(168 85 247 / 0.3);
  color: white;
  height: 44px;
  text-align: left;
  transition: background-color 0.2s, border-color 0.2s;
  font-weight: 500;
  padding: 4px 96px 4px 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
  width: -webkit-fill-available;

  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  :focus-visible {
    outline: 1px solid rgba(168, 85, 247, 0.7);
    outline-offset: 1px;
  }

  :disabled {
    cursor: auto;
    opacity: 0.5;
  }
`;

export const PromocodeButton = styled.button<{ isPromoApplied?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 1rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.2s;
  color: white;
  font-size: 14px;

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }

  ${({ isPromoApplied }) =>
    isPromoApplied
      ? css`
          background-color: #48bb78;

          &:hover {
            background-color: #38a169;
            cursor: default;
          }
        `
      : css`
          background: linear-gradient(to right, #7b2d9e, #d53f8c);

          &:hover {
            background: linear-gradient(to right, #6f2b8d, #d1387e);
          }
        `}
`;

export const ButtonPay = styled.button`
  height: 48px;
  padding: 8px 56px;
  background: linear-gradient(to right, #7b2d9e, #d53f8c);
  color: white;
  font-size: 18px;
  font-weight: 500;
  border-radius: 9999px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;

  &:hover {
    background: linear-gradient(to right, #6f2b8d, #d1387e);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const PayWrapper = styled(motion.div)`
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  margin-top: 3.3rem;
  margin-bottom: 5%;
  transition: all 0.2s;
`;
