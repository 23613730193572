import { Box } from '@mui/material';

import { AddVideoIcon } from '@/components/SvgIcons/AddVideoIcon';
import {
  GradientBackground,
  InnerContainer,
  LabelTextPurple,
  LabelTextPurple2,
  LabelTextPurple3,
  OuterContainer,
  ProgressInfoBlock,
  SecondaryTextLoading,
  StyledLinearProgress,
  UploadContainer,
} from '@/components/UploadProgress';

export const AnimatedComponent = ({ videoName, progress }: { videoName: string; progress: number }) => {
  return (
    <UploadContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '6px',
          marginTop: '8px',
        }}
      >
        <OuterContainer>
          <GradientBackground />
          <InnerContainer>
            <div style={{ position: 'relative', left: '10%' }}>
              <AddVideoIcon />
            </div>
          </InnerContainer>
        </OuterContainer>
        <SecondaryTextLoading>Загрузка видео...</SecondaryTextLoading>
        <LabelTextPurple>{videoName}</LabelTextPurple>
        <ProgressInfoBlock>
          <LabelTextPurple2>Загрузка и обработка видео</LabelTextPurple2>
          <LabelTextPurple>{`${Math.round(progress)}%`}</LabelTextPurple>
          {/*<SecondaryTextStyled*/}
          {/*  style={{*/}
          {/*    textDecoration: 'underline',*/}
          {/*  }}*/}
          {/*  onClick={handleCancelUpload}*/}
          {/*>*/}
          {/*  {t('download_file_skip')}*/}
          {/*</SecondaryTextStyled>*/}
        </ProgressInfoBlock>
        <div
          style={{
            // background: 'linear-gradient(to right, #6b5bff, #ff6b81)',
            padding: '1px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <StyledLinearProgress variant="determinate" value={progress} />
          </Box>
        </div>

        <LabelTextPurple3>Пожалуйста, не закрывайте окно до завершения загрузки</LabelTextPurple3>
      </Box>
    </UploadContainer>
  );
};
