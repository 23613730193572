import styled from 'styled-components';
import Button from '@/components/Shared/Buttons/Button/Button';

export const CopyButton = styled(Button)`
  background-color: rgb(26, 17, 69);

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const CopyButtonText = styled.span`
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
