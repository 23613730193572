import { VideoCardV2Wrapper } from '@/pages/VideoPage/VideoPage.styled';
import { TitleTranscript } from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';
import {
  ScrollBlock,
  Text,
  Timecodes,
  TimecodesContentWrap,
  TimecodesTitleTab,
  TimeTitle,
  TitleTimecodesWrapper,
} from '@/pages/VideoPage/TimecodesTab/TimecodesTab.styled';
import { useHandlingError } from '@/hooks';
import { useGetTimecodesQuery } from '@/api';
import { useEffect, useMemo, useRef } from 'react';
import { StatusType } from '@/types';
import { getMinutes } from '@/pages/VideoPage/helpers';
import CircularProgress from '@mui/material/CircularProgress';
import { IframeThemeType } from '@/types/iframeTypes';
import { theme } from '@/styles';
import { TimecodesDescription } from '@/pages/VideoPage/TimecodesTab/TimecodesDescription';
import { FragmentTimeV2, ShareIconWrapper } from '@/components/Card/VideoFragmentCard/VideoFragmentCard.styled';

interface TimecodesProps {
  setTime: (time: number) => void;
  id: string;
  currentTime?: number | null;
  hash?: string;
  timecodesStatus: StatusType | undefined;
  titleVideo: string;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const TimecodesTab = ({
  setTime,
  id,
  currentTime,
  hash,
  timecodesStatus,
  titleVideo,
  isIframe,
  iframeTheme,
}: TimecodesProps) => {
  // const { t } = useTranslation('components');
  const { catchError } = useHandlingError();
  // const isMobile = useMatchMedia('(max-width: 526px)');

  const { data, isLoading, error } = useGetTimecodesQuery({ videoPublicId: id ?? '', hash });
  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const timecodeTitleRef = useRef<HTMLHeadingElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const timings = useMemo(() => data?.map((array) => array.start as number) ?? [], [data]);

  useEffect(() => {
    if (listRef.current) {
      const children = Array.from(listRef.current.children) as HTMLLIElement[];
      children.forEach((child, index) => {
        if (currentTime && Math.round(currentTime) === timings[index]) {
          wrapperRef.current?.scrollTo({ top: child.offsetTop, behavior: 'smooth' });
        }

        if (
          currentTime &&
          currentTime >= timings[index] &&
          (timings[index + 1] === undefined || currentTime < timings[index + 1]) &&
          timecodeTitleRef.current
        ) {
          timecodeTitleRef.current.style.backgroundColor = '#BE9F4B';
        } else {
          timecodeTitleRef.current && (timecodeTitleRef.current.style.backgroundColor = 'transparent');
        }
      });
    }
  }, [currentTime]);

  if (timecodesStatus === 'NOT_STARTED') {
    return (
      <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        Видео обрабатывается
      </Text>
    );
  }

  const isTarget = (index: number) =>
    Boolean(
      currentTime &&
        currentTime >= timings[index] &&
        (timings[index + 1] === undefined || currentTime < timings[index + 1]),
    );

  return (
    <VideoCardV2Wrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} ref={wrapperRef}>
      {(timecodesStatus === 'IN_PROGRESS' || timecodesStatus === 'FAILED') && data && data.length === 0 && (
        <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          Видео обрабатывается
        </Text>
      )}
      {isLoading && (
        <div style={{ margin: '0 auto' }}>
          <CircularProgress color="secondary" size={40} />
        </div>
      )}
      {data && data.length > 0 && (
        <div>
          <TitleTimecodesWrapper>
            <TitleTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
              {titleVideo}
            </TitleTranscript>
            {/*<ButtonTimecodesWrapper>*/}
            {/*  <ButtonTimecodes isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
            {/*    <Copy />*/}
            {/*    {!isMobile && t('copy_timecodes')}*/}
            {/*  </ButtonTimecodes>*/}
            {/*  <ButtonTimecodes isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
            {/*    <DownloadIcon />*/}
            {/*    {!isMobile && t('download')}*/}
            {/*  </ButtonTimecodes>*/}
            {/*</ButtonTimecodesWrapper>*/}
          </TitleTimecodesWrapper>
          <ScrollBlock style={{ height: '495px' }} isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            <Timecodes ref={listRef}>
              {data.map(({ start, text, title }, index) => (
                <TimecodesContentWrap
                  isIframe={isIframe}
                  iframeTheme={iframeTheme as IframeThemeType}
                  onClick={() => setTime(start as number)}
                  key={index}
                >
                  <FragmentTimeV2
                    style={{
                      color: `${
                        isIframe && iframeTheme === IframeThemeType.light
                          ? theme.colors.blue.blue_dark
                          : 'rgb(192 132 252)'
                      }`,
                    }}
                  >
                    {getMinutes(start as number)}
                  </FragmentTimeV2>
                  <TimeTitle>
                    <TimecodesTitleTab
                      isIframe={isIframe}
                      iframeTheme={iframeTheme as IframeThemeType}
                      isTarget={isTarget(index)}
                    >
                      {title}
                    </TimecodesTitleTab>
                    <TimecodesDescription
                      isIframe={isIframe}
                      iframeTheme={iframeTheme as IframeThemeType}
                      text={text}
                    />
                  </TimeTitle>
                  <ShareIconWrapper style={{ maxHeight: 'max-content' }}>
                    {/*<Share*/}
                    {/*  color={isIframe && iframeTheme === IframeThemeType.light ? 'rgb(37 26 86)' : 'rgb(168 85 247)'}*/}
                    {/*/>*/}
                  </ShareIconWrapper>
                </TimecodesContentWrap>
              ))}
            </Timecodes>
          </ScrollBlock>
        </div>
      )}
    </VideoCardV2Wrapper>
  );
};
