import styled, { css } from 'styled-components';
import { IframeThemeType } from '@/types/iframeTypes';
import { theme } from '@/styles';

export const TextWrapper = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  display: flex;
  padding-left: 80px;

  ${({ iframeTheme }) =>
    iframeTheme &&
    css`
      padding-left: 130px;
    `};

  @media (max-width: 768px) {
    padding-left: 60px;

    ${({ iframeTheme, isIframe }) =>
      isIframe &&
      iframeTheme &&
      css`
        padding-left: 100px;
      `};
  }

  @media (max-width: 480px) {
    padding-left: 60px;

    ${({ iframeTheme, isIframe }) =>
      isIframe &&
      iframeTheme &&
      css`
        padding-left: 70px;
      `};
  }
`;

export const TimecodesDescriptionText = styled.span<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: #d8b4fe;
  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: rgb(26, 17, 69);
    `}
`;

export const TextLink = styled.span<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  margin-left: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1028px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 14px;
  }
  cursor: pointer;
  :hover {
    color: ${theme.colors.text.white_80};
  }
  transition: color 0.35s ease-in-out;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const LinkDescription = styled(TextLink)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: rgb(26, 17, 69);
    `}
`;
