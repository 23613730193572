import { Wrapper, WrapperForSlim } from './EditQuizPage.styled';
import { EditQuizForm } from './QuizForm';

import { Title } from '../AdminPage.styled';

import { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';

import { useGetAllQuizzesAdminQuery, videosAPI } from '@/api';
import { FileVideoPlayer, FullScreenLoader, RutubeVideoPlayer } from '@/components';
import { ScrollToTopButton } from '@/components/ScrollToTop';
import { useMatchMedia } from '@/hooks';
import { VideoWrapper } from '@/pages/VideoPage/VideoPage.styled';
import { QuizApiResponse, QuizFormState } from '@/types';
import { formatDuration, parseDuration } from '@/utils';

const transformToQuizFormData = ({ data, publicId }: QuizApiResponse): QuizFormState => ({
  publicId: publicId,
  quizzes: data.map(({ chapter, quiz }) => ({
    chapter: { ...chapter, start: formatDuration(Math.round(chapter.start as number)) },
    quiz: quiz.map(({ correctAnswer, question, wrongAnswers }) => ({
      question,
      answers: [
        { answer: correctAnswer, isCorrect: true },
        ...wrongAnswers.map((answer) => ({ answer, isCorrect: false })),
      ],
    })),
  })),
});

export const EditQuizPage = () => {
  const { videoId } = useParams();
  const { t } = useTranslation('loading');
  const iframeWrapper = useRef<HTMLDivElement>(null);
  const ytPlayerRef = useRef<YouTube>(null);
  const vkRef = useRef<HTMLIFrameElement>(null);
  const iframe = useRef<{ goToTime: (time: number) => void } | null>(null);
  const isTablet = useMatchMedia('(max-width: 768px)');
  const height = isTablet ? '300px' : '500px';

  const { data: quizzes, isLoading } = useGetAllQuizzesAdminQuery(
    { videoId: videoId || '' },
    {
      skip: !videoId,
      selectFromResult: (data) => {
        return {
          ...data,
          data: data.data && transformToQuizFormData(data.data),
        };
      },
    },
  );

  const { data: video } = videosAPI.useGetMovieByIdQuery({ id: videoId ?? '' });

  const goToTime = useCallback(
    (time: string | number) => {
      const numberTime = parseDuration(time as string);

      if (isNaN(numberTime)) {
        return;
      }
      if (video && video.source === 'VK' && vkRef.current) {
        // TODO разобраться с типизацией
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const playerVK = window.VK.VideoPlayer(vkRef.current);
        playerVK.seek(numberTime);

        iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }

      ytPlayerRef.current?.internalPlayer.seekTo(numberTime, true);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  if (quizzes?.quizzes.length === 0 && !isLoading) {
    return <Title>{t('load_quiz')}</Title>;
  }

  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}

      {quizzes && quizzes?.quizzes.length > 0 && (
        <EditQuizForm videoId={video?.publicId || ''} defaultValues={quizzes} goToTime={goToTime} />
      )}

      <WrapperForSlim>
        {video && (
          <VideoWrapper ref={iframeWrapper}>
            {video.source === 'YOUTUBE' && (
              <YouTube
                videoId={video.videoId}
                title={video.title}
                ref={ytPlayerRef}
                style={{ width: '100%', height: '308px' }}
                opts={{
                  height: '308px',
                  width: '100%',
                  playerVars: {
                    autoplay: 0,
                    rel: 0,
                  },
                }}
              />
            )}

            {video.source === 'VK' && (
              <iframe
                ref={vkRef}
                title={video.title}
                src={`${video.originLink}&hd=2&js_api=1`}
                width="100%"
                height="308px"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              ></iframe>
            )}

            {video.source === 'UPLOADED' && <FileVideoPlayer ref={iframe} height={height} url={video.originLink} />}

            {video.source === 'RUTUBE' && <RutubeVideoPlayer ref={iframe} height={height} videoId={video.videoId} />}
          </VideoWrapper>
        )}
      </WrapperForSlim>
      <ScrollToTopButton />
    </Wrapper>
  );
};
