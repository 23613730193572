import { useEffect, useRef } from 'react';

import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useDebounce } from '@/hooks';
import { IconWrapper, Wrapper } from '@/components/Shared/SearchInVideoInput/SearchInVideoInputStyle';
import { StyledInputV2 } from '@/pages/VideoPage/SearchInVideoInputV2/SearchInVideoInputV2.styled';
import { Search } from '@mui/icons-material';
import { IframeThemeType } from '@/types/iframeTypes';

interface SearchInVideoInputV2Props {
  getSearch: (value: string) => Promise<void>;
  disabled?: boolean;
  placeholder?: string;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const SearchInVideoInputV2 = ({
  isIframe,
  iframeTheme,
  getSearch,
  disabled = false,
  placeholder,
}: SearchInVideoInputV2Props) => {
  const { t } = useTranslation('components');
  const [param, setParam] = useSearchParams();
  const search = param.get('search') || '';

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const data = searchInputRef.current?.value || '';

    if (data) {
      getSearch(data);
    }
  }, []);

  const makeSearch = useDebounce(() => {
    const data = searchInputRef.current?.value || '';
    if (data) {
      setParam((prev) => {
        prev.set('search', data);
        return prev;
      });
      getSearch(data);
      ReactGA.event({
        category: 'Search',
        action: 'Search in playlist',
      });
    } else {
      setParam((prev) => {
        prev.delete('search');
        return prev;
      });
    }
  }, 500);

  const onSearch = () => {
    makeSearch();
  };

  return (
    <Wrapper>
      <StyledInputV2
        isIframe={isIframe}
        iframeTheme={iframeTheme as IframeThemeType}
        placeholder={placeholder ?? t('search_in_video')}
        ref={searchInputRef}
        onChange={onSearch}
        defaultValue={search}
        disabled={disabled}
        icon={
          <IconWrapper>
            <Search style={{ color: '#a855f7' }} />
          </IconWrapper>
        }
      />
    </Wrapper>
  );
};
