import { ContainerStyled } from './Container.styled';

import { PropsWithChildren } from 'react';

import { useIframe } from '@/hooks';
import { IframeThemeType } from '@/types/iframeTypes';

export const Container = ({ children }: PropsWithChildren) => {
  const { iframeTheme } = useIframe();
  return <ContainerStyled iframeTheme={iframeTheme as IframeThemeType}>{children}</ContainerStyled>;
};
