import { memo, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { useGetTranscriptQuery } from '@/api';
import { useHandlingError } from '@/hooks';
import { ScrollBlock, Text } from '@/pages/VideoPage/TimecodesTab/TimecodesTab.styled';
import {
  TextTranscript,
  TitleTranscript,
  TitleTranscriptWrapper,
} from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';
import { ContentWrap, VideoCardV2Wrapper } from '@/pages/VideoPage/VideoPage.styled';
import { StatusType } from '@/types';
import { IframeThemeType } from '@/types/iframeTypes';

export const TranscriptVideo = memo(
  ({
    id,
    transcriptStatus,
    isIframe,
    iframeTheme,
  }: {
    id: string;
    transcriptStatus: StatusType | undefined;
    isIframe?: boolean;
    iframeTheme?: string;
  }) => {
    const { t } = useTranslation('components');
    const { catchError } = useHandlingError();

    const { data, isLoading, error } = useGetTranscriptQuery({ publicId: id ?? '' }, { skip: !id });

    useEffect(() => {
      if (error) {
        catchError(error);
      }
    }, [error]);

    if (transcriptStatus !== 'DONE' && transcriptStatus !== 'NOT_ORDERED') {
      return (
        <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          Видео обрабатывается
        </Text>
      );
    }

    if (transcriptStatus === 'NOT_ORDERED') {
      return null;
    }

    return (
      <VideoCardV2Wrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        <TitleTranscriptWrapper>
          <TitleTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {t('title_transcript')}
          </TitleTranscript>
          {/*<ButtonTranscriptWrapper>*/}
          {/*  <ButtonTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
          {/*    <Copy />*/}
          {/*    {!isMobile && t('copy_transcript')}*/}
          {/*  </ButtonTranscript>*/}
          {/*  <ButtonTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
          {/*    <DownloadIcon />*/}
          {/*    {!isMobile && 'PDF'}*/}
          {/*  </ButtonTranscript>*/}
          {/*</ButtonTranscriptWrapper>*/}
        </TitleTranscriptWrapper>
        <ContentWrap isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          {isLoading && (
            <div style={{ margin: '0 auto' }}>
              <CircularProgress color="secondary" size={40} />
            </div>
          )}
          {transcriptStatus === 'DONE' && data && data.cues.length > 0 && (
            <ScrollBlock isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
              {data.cues?.map((item) => (
                <TextTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} key={item.start}>
                  {' '}
                  {item.text}
                </TextTranscript>
              ))}
            </ScrollBlock>
          )}
        </ContentWrap>
      </VideoCardV2Wrapper>
    );
  },
);

TranscriptVideo.displayName = 'TranscriptVideo';
