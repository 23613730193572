import styled from 'styled-components';

export const DropDownMenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 10px 24px;

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const DropDownMenuItemIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
