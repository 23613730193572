import { Wrapper } from './FullScreenLoader.styled';

import CircularProgress from '@mui/material/CircularProgress';

export const FullScreenLoader = ({ size = '60px' }: { size?: string }) => {
  return (
    <Wrapper>
      <CircularProgress size={size} color="inherit" />
    </Wrapper>
  );
};

export default FullScreenLoader;
