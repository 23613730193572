// import Slider from "react-slick";
// import { useAppDispatch, useTypedSelector } from "../../hooks/useStore";
// import { fetchPlaylistsUnLogged } from "../../store/slices/playlistsSlice";
import { Inner, Wrapper } from './MainPage.styled';

import { playlistsAPI } from '../../api/playlistsApi';
import { PlaylistCard } from '../../components/Card/PlaylistCard';
import FullScreenLoader from '../../components/Shared/FullScreenLoader/FullScreenLoader';
import { useHandlingError } from '../../hooks/useErrorHandling';
import { usePagination } from '../../hooks/usePagination';
import { useAppSelector } from '../../hooks/useStore';
import { CardsList, PaginationWrapper } from '../../styles/components';
import { InfoTitle } from '../LibraryPage';

import { memo, useEffect } from 'react';

import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const { t } = useTranslation('pages');
  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const [, { search, statusSearch }] = useAppSelector((state) => [state.user.user_id, state.search]);

  const { catchError } = useHandlingError();
  const { data, isLoading, isSuccess, error, isFetching } = playlistsAPI.useGetPlaylistsQuery(
    { params: { page, title: search ?? undefined } },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  // const prefetchPage = playlistsAPI.usePrefetch('getPlaylists')

  // const prefetchNext = useCallback(() => {
  //   prefetchPage({page: page + 1, search})
  // }, [prefetchPage, page])

  // useEffect(() => {
  //   if (data && data.next) {
  //     if (data && pages > 1) {
  //       [...new Array(pages)].forEach((page, index) => {
  //         if (index >= pages) return
  //         prefetchPage({page: index + 1, search}, { force: true })
  //       })
  //     }
  //   }
  // }, [prefetchPage])

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   padding: 20,
  //   slidesToScroll: 2,
  // };

  useEffect(() => {
    if (isSuccess) {
      setCount(data.results.length === 0 ? 1 : data.count);
    } else if (error) {
      catchError(error);
      console.log(error);
    }
  }, [data, error]);

  return (
    <Wrapper>
      {isSuccess && data.results && data.results.length === 0 && (
        <InfoTitle>{statusSearch ? t('main.no_result') : t('main.no_pl')}</InfoTitle>
      )}
      <Inner>
        {(isLoading || isFetching) && <FullScreenLoader />}
        <CardsList>
          {isSuccess &&
            data.results.map((playlist, index) => <PlaylistCard key={index} isUserOwner={false} playlist={playlist} />)}
        </CardsList>
      </Inner>

      {/* <SliderSection>
        <Title>Популярное</Title>
        <Slider {...settings}>
          {playListsUnLogged.slice(0, 10).map((playlist, index) => (
            <ItemWrapper key={index}>
              <PlayLIstCard  clickable={false} playList={playlist} />
            </ItemWrapper>
          ))}
        </Slider>
      </SliderSection> */}
      {/*<SliderSection>
        <Title>Декор</Title>
        <Slider {...settings}>
          {playListsUnLogged.slice(0, 10).map((playlist, index) => (
            <ItemWrapper key={index}>
              <PlayLIstCard clickable={false} playList={playlist} />
            </ItemWrapper>
          ))}
        </Slider>
      </SliderSection>
      <SliderSection>
        <Title>Позалипать</Title>
        <Slider {...settings}>
          {playListsUnLogged.slice(0, 10).map((playlist, index) => (
            <ItemWrapper key={index}>
              <PlayLIstCard clickable={false} playList={playlist} />
            </ItemWrapper>
          ))}
        </Slider>
      </SliderSection> */}
      {!isLoading && pages > 1 && (
        <PaginationWrapper>
          <Pagination count={pages} page={page} color="primary" onChange={handleChangePage} />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

export default memo(MainPage);
