import { InnerAuth, Form, ToRegistrationButton } from './Login.styled';

import Button from '../../Buttons/Button/Button';
import { Modal, OtherText } from '../Modal';
import { Info } from '../Registration/Registration.styled';
import { FieldsWrapper, SubmitButton } from '../Registration/RegistrationModal/B2CForm/B2CForm.styled';
import { MyInput } from '../Registration/RegistrationModal/B2CForm/MyInput';
import { Header, HeaderTitle } from '../Registration/RegistrationModal/RegistrationModal.styled';

import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { authAPI, usersAPI } from '@/api';
import { useActions, useAppSearchParams, useHandlingError } from '@/hooks';
import { RegisterUser } from '@/types';
import { isAuthData } from '@/utils/isAuthData';

export const Login = () => {
  const { t } = useTranslation('components');

  const { setAppParams, deleteAppParam } = useAppSearchParams();

  const { catchError } = useHandlingError();

  const [loginUser, status] = authAPI.useLoginUserMutation();

  const { closeModal, showNotification } = useActions();

  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(false);
  const [errorResend, setErrorResend] = useState<boolean>(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>('');

  const closeHandler = () => {
    reset();
    setErrorAlertIsOpen(false);
    closeModal();
    deleteAppParam('popup');
  };

  const successHandler = () => {
    closeHandler();

    showNotification({
      text: t('welcome', { ns: 'notification' }),
      severity: 'success',
    });
  };

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<RegisterUser>();

  const [resetEmail, statusResend] = usersAPI.useResendActivationMutation();

  const resendHandler = async () => {
    try {
      resetEmail({ email: getValues('email') }).unwrap();
      setErrorResend(false);
      setErrorAlertIsOpen(false);
    } catch (err) {
      catchError(err);
    }
  };

  const onSubmit = async (data: RegisterUser) => {
    try {
      await loginUser({ ...data }).unwrap();
      successHandler();
      ReactGA.event({
        category: 'login',
        action: 'login',
      });
    } catch (err) {
      const errorData = (err as FetchBaseQueryError).data;
      if (isAuthData(errorData)) {
        let errorMessage = '';
        if (errorData.detail.includes('inactive')) {
          errorMessage = t('acc_inactive', { ns: 'error' });
        } else {
          errorMessage = t('req_field_login', { ns: 'error' });
        }
        setErrorAlertMessage(errorMessage);
        setErrorAlertIsOpen(true);
      }
      catchError(err);
    }
  };

  const registerHandler = () => {
    closeHandler();
    setAppParams('popup', 'registration');
  };

  const onInfoClickHandler = () => {
    setAppParams('popup', 'resetpassword');
  };

  const resetErrors = () => {
    setErrorAlertIsOpen(false);
  };

  return (
    <Modal modal={'login'} onClose={closeHandler} closeButton>
      <Header>
        <HeaderTitle>{t('menu.in', { ns: 'header' })}</HeaderTitle>
      </Header>
      <InnerAuth modal={'login'}>
        <Collapse in={errorAlertIsOpen}>
          <Alert
            severity="error"
            variant="filled"
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={resetErrors}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>{t('log', { ns: 'error' })}</AlertTitle>
            {errorAlertMessage}
            {errorResend && (
              <>
                <Info style={{ display: 'flex', gap: '10px', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Button
                    style={{ padding: '0px', fontSize: '10px' }}
                    size="small"
                    theme="transparent"
                    onClick={resendHandler}
                    disabled={statusResend.isLoading}
                  >
                    {t('resend', { ns: 'error' })}
                  </Button>
                </Info>
              </>
            )}
          </Alert>
        </Collapse>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldsWrapper>
            <MyInput
              required
              label={t('form.email')}
              {...register('email', {
                required: { value: true, message: t('req_field', { ns: 'error' }) },
                minLength: { value: 5, message: t('min_char', { ns: 'error', length: 5 }) },
                maxLength: { value: 256, message: t('max_char', { ns: 'error', length: 256 }) },
                pattern: {
                  value: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
                  message: t('incor_email', { ns: 'error' }),
                },
              })}
              // placeholder={t('form.email')}
              error={errors.email}
            />

            <MyInput
              required
              label={t('form.password')}
              type="password"
              {...register('password', {
                required: { value: true, message: t('req_field', { ns: 'error' }) },
                minLength: { value: 6, message: t('min_char', { ns: 'error', length: 6 }) },
                maxLength: { value: 128, message: t('max_char', { ns: 'error', length: 128 }) },
              })}
              error={errors.password}
            />
            <Info onClick={onInfoClickHandler}>{t('form.forgot_pass')}</Info>
            {/* </PasswordWrapper> */}
          </FieldsWrapper>

          <SubmitButton type="submit" disabled={status.isLoading}>
            {t('menu.in', { ns: 'header' })}
          </SubmitButton>
        </Form>
        <ToRegistrationButton onClick={registerHandler}>
          <OtherText>{t('log_no_acc', { ns: 'modal' })}</OtherText>
          <OtherText style={{ textDecoration: 'underline' }}>{t('reg', { ns: 'modal' })}</OtherText>
        </ToRegistrationButton>
      </InnerAuth>
    </Modal>
  );
};

export default Login;
