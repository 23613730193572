import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme } from '@/styles';

export const FeedbackStyled = styled.section`
  padding: 96px 0;
`;

export const FeedbackWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const QuestionWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 32px;

  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.text.purple_200};
`;

export const QuestionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
`;

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;
`;
export const SupportItemsWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    color: inherit;
  }
`;

export const Title = styled.h2`
  font-size: 48px;
  line-height: 48px;
  color: transparent;
  font-weight: 700;
  background-clip: text;
  user-select: 'none';
  background-image: linear-gradient(to right, #f3e8ff, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const SubtitleList = styled.h3`
  color: ${theme.colors.text.white_100};
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;

export const FormWrapper = styled(motion.div)`
  position: relative;
`;

export const FormSideWrapper = styled.div`
  position: absolute;
  transition-duration: 300ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-image: linear-gradient(to right, ${theme.colors.text.purple_500_10}, ${theme.colors.pink.pink_500_10});
  transition-duration: 300ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  inset: 0px;
  border-radius: 12px;
  transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(0) scaleY(0);

  ${FormWrapper}:hover & {
    opacity: 1;
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.05) scaleY(1.05);
  }
`;
