import styled from 'styled-components';

import { theme } from '@/styles';

type HeaderStyleProps = {
  isOpen?: boolean;
};

export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  z-index: 51;
  background-color: rgb(15, 10, 43);
`;
export const BalanceButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background-color: #251a56;
  padding: 14px 24px;
  border-radius: 12px;
  transition: color 0.2s;
  //min-width: 120px;
  justify-content: center;

  &:hover {
    color: #d8b4fe;
  }
`;

export const BalanceText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const Inner = styled.div<{ balancePage?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 94px;

  @media (max-width: 725px) {
    width: 97%;
    // position: ${(props) => (props.balancePage ? 'relative' : 'sticky')};
    //  top: 0;
    //z-index: 10;
    //padding-bottom: 10px;
    //background-color: ${theme.colors.blue.blue_modal};
    //width: 95%;
  }

  //@media (max-width: 417px) {
  //  width: 95%;
  //  padding-right: 20px;
  //}

  //@media (max-width: 1024px) {
  //  grid-template-columns: minmax(48px, 64px) 1fr 78px;
  //  min-height: 94px;
  //}
`;

export const InputWrapper = styled.div<HeaderStyleProps>`
  padding: 0 10px 0 39px;
  max-width: 534px;

  button {
    display: none;
  }

  @media (max-width: 1250px) {
    padding-left: 10px;
  }

  @media (max-width: 1024px) {
    position: absolute;
    /* display: ${(props) => (props.isOpen ? 'block' : 'none')}; */
    width: 100%;
    padding: 0;
    margin: 0;
    top: 25px;
    right: 20px;
    left: 2px;
    z-index: 10;
    //width: auto;
    transition: all 0.5s;

    transform: ${(props) => (props.isOpen ? 'translateY(0%)' : 'translateY(-200%)')};
    /* opacity: ${(props) => (props.isOpen ? '1' : '0')}; */
    button {
      display: block;
      position: absolute;
      right: 15px;
      top: 14px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;

  //@media (max-width: 960px) {
  //  margin-right: 45px;
  //}

  //button:nth-child(1) {
  //  display: none;
  //}

  button:nth-child(4) {
    display: none;
  }

  //@media (max-width: 768px) {
  //  margin-right: 36px;
  //}
  @media (max-width: 1200px) {
    //button:nth-child(3) {
    //  display: block;
    //}
  }

  @media (max-width: 1024px) {
    /* margin-left: auto; */
    gap: 15px;
    button:nth-child(1) {
      display: block;
    }

    button:nth-child(3) {
      display: none;
    }

    button:nth-child(4) {
      display: block;
    }
  }

  @media (max-width: 885px) {
    //button:nth-child(n + 2) {
    //  display: none;
    //}
    //button:nth-child(4) {
    //  display: none;
    //}
  }

  @media (max-width: 640px) {
    button:nth-child(2) {
      display: none;
    }
  }
`;
