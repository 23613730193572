import { Buttons } from './EditSummaryPage.styled';

import { useState } from 'react';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePatchSummaryAdminMutation } from '@/api';
import { Button, RemoveSummary } from '@/components';
import { useActions } from '@/hooks';

export const SummaryEditor = ({
  contentState,
  publicId,
}: {
  publicId: string;
  contentState: ContentState;
  isEmpty?: boolean;
}) => {
  const { videoId } = useParams();
  const { t } = useTranslation('pages');
  const { showNotification, openModal } = useActions();

  const [value, setValue] = useState(() => EditorState.createWithContent(contentState));

  const [updateSummary, { isLoading }] = usePatchSummaryAdminMutation();

  const handleSaveSummary = async () => {
    try {
      const content = value.getCurrentContent();
      // const rawObject = convertToRaw(content);
      // const markdownString = draftToMarkdown(rawObject);
      // console.log({ markdownString });
      if (content.hasText()) {
        const rawObject = convertToRaw(value.getCurrentContent());
        const markdownString = draftToMarkdown(rawObject);
        await updateSummary({ publicId, body: { markdown: markdownString }, videoId: videoId ?? '' }).unwrap();
        showNotification({
          text: t('save_summary', { ns: 'success' }),
          severity: 'success',
        });
        return;
      }
    } catch (e) {
      showNotification({
        text: t('save_summary', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const handleDeleteSummary = () => {
    openModal({ active: 'RemoveSummary' });
  };

  const editorStyle = {
    border: '2px solid #E4E4FF',
    borderRadius: '10px',
    padding: '12px 16px 14px 16px',
    marginTop: '20px',
    minHeight: '300px',
    maxHeight: '565px',
    maxWidth: '100%',
  };

  const toolbarStyle = {
    background: '#140150',
    border: 'none',
    borderRadius: '10px',
    padding: '10px 20px',
    // maxWidth: '55%',
    display: 'flex',
  };

  return (
    <>
      <Editor
        toolbarClassName="toolbarClassName"
        editorState={value}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={setValue}
        editorStyle={editorStyle}
        toolbarStyle={toolbarStyle}
      />
      <Buttons>
        <Button theme="black" onClick={handleSaveSummary} disabled={isLoading}>
          {t('admin.save')}
        </Button>
        <Button onClick={handleDeleteSummary} disabled={isLoading}>
          {t('admin.delete')}
        </Button>
      </Buttons>
      <RemoveSummary publicId={publicId} videoId={videoId || ''} />
    </>
  );
};
