import { InDev } from './App.styled';
import ScrollToTop from './components/ScrollToTop';
import FullScreenLoader from './components/Shared/FullScreenLoader/FullScreenLoader';
import AddMobile from './components/Shared/Modals/AddMobile/AddMobile';
import * as analytics from './ga4';
import RequireAuth from './hoc/RequireAuth';
import { EditQuizPage } from './pages/AdminPage/EditQuizPage';
import { EditSummaryPage } from './pages/AdminPage/EditSummaryPage';
import { EditTimecodesPage } from './pages/AdminPage/EditTimecodesPage';
import { EditTranscriptPage } from './pages/AdminPage/EditTranscriptPage';
import { PlayListPageAdmin } from './pages/AdminPage/PLayListPageAdmin/PlayListPageAdmin';

import { Suspense, useEffect } from 'react';

import { Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import {
  AddVideo,
  CreatePlaylist,
  Login,
  Notification,
  Registration,
  RemoveVideo,
  RemoveVideoFile,
  ResendEmail,
  ResetPassword,
  VerifyInfo,
} from '@/components';
import { useActions, useAppSelector, useIframe } from '@/hooks';
import { MainLayout, ResultsLayout, SearchLayout } from '@/layouts';
import {
  ActivateLazy,
  AdminPage,
  AdminPlaylists,
  AdminVideoOutletLazy,
  AdminVideos,
  BalancePage,
  LendingLazy,
  Library,
  NotFoundPage,
  Password,
  PersonalDataPolicy,
  Playlist,
  PlayListOutlet,
  PlaylistPrivate,
  PlaylistsWrapper,
  PlaylistView,
  Policy,
  PrivacyPolicy,
  Profile,
  SearchResult,
  Statistic,
  SuccessPaymentPage,
  TermsOfUse,
  VideoPage,
  VideosWrapper,
} from '@/pages';
import { ModalType } from '@/store/slices/modalsSlice';
import Global from '@/styles/global';
import { IframeThemeType } from '@/types';

function App() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const { openModal } = useActions();
  const [/* onBoarding,*/ isAuth] = useAppSelector((state) => [/* state.user.onBoarding, */ state.user.isAuth]);
  const [params] = useSearchParams();
  const location = useLocation();
  const { isIframe, iframeTheme } = useIframe();

  useEffect(() => {
    if (!isAuth && params.has('popup') && !location.pathname.includes('embed')) {
      openModal({ active: params.get('popup') as ModalType });
    }
  }, [isAuth, params]);

  const setLayout = () => (location.pathname.includes('search') || !isAuth ? <Outlet /> : <MainLayout />);

  return (
    <>
      <ScrollToTop />
      {/* {(onBoarding === null || onBoarding === 'watch') && !isIframe && isAuth ? (
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<FullScreenLoader />}>
                <OnboardingLazy />
              </Suspense>
            }
          />
        </Routes>
      ) : ( */}
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path="/" element={setLayout()} errorElement={<InDev />}>
            <Route
              element={
                <RequireAuth>
                  <Library />
                </RequireAuth>
              }
            >
              <Route index element={<PlaylistsWrapper />} />
              <Route path="videos" element={<VideosWrapper />} />
              <Route path="videos/watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="landing" element={<LendingLazy />} />

            <Route path="/balance" element={<BalancePage />} />
            {/* <Route path="user/:id" element={<NotFoundPage />} /> */}
            <Route path="playlistview/:playlistId" element={<PlayListOutlet />}>
              <Route index element={<PlaylistView />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="embed/playlistview/:playlistId" element={<PlayListOutlet />}>
              <Route index element={<PlaylistView />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="playlist/:playlistId" element={<PlayListOutlet />}>
              <Route index element={<Playlist />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="private/:playlistId/:hash" element={<PlayListOutlet />}>
              <Route index element={<PlaylistPrivate />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="embed/playlist/:playlistId" element={<PlayListOutlet />}>
              <Route index element={<Playlist />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="videos" element={<PlayListOutlet />}>
              <Route path=":id" element={<VideoPage />} />
            </Route>
            <Route path="embed/videos" element={<PlayListOutlet />}>
              <Route path=":id" element={<VideoPage />} />
            </Route>

            <Route path="policy" element={<Policy />}>
              <Route path="personal" element={<PersonalDataPolicy />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="terms_of_use" element={<TermsOfUse />} />
            </Route>
            <Route path="activate/:uid/:token" element={<ActivateLazy />} />

            <Route path="password/reset/:uid/:token" element={<Password />} />

            <Route path="private/:playlistId/:hash" element={<PlayListOutlet />}>
              <Route index element={<PlaylistPrivate />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>
            <Route path="videos" element={<PlayListOutlet />}>
              <Route path=":id" element={<VideoPage />} />
            </Route>
            <Route path="embed/videos" element={<PlayListOutlet />}>
              <Route path=":id" element={<VideoPage />} />
              {/*<Route path=":id" element={<Video />} />*/}
            </Route>

            <Route path="policy" element={<Policy />}>
              <Route path="personal" element={<PersonalDataPolicy />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="terms_of_use" element={<TermsOfUse />} />
            </Route>
            <Route path="activate/:uid/:token" element={<ActivateLazy />} />

            <Route path="password/reset">
              <Route path=":uid">
                <Route path=":token" element={<Password />} />
              </Route>
            </Route>

            {/* required pages */}
            <Route
              path="profile"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route index element={<Profile />} />
              <Route path="statistics" element={<Statistic />} />
            </Route>
            <Route path="search">
              <Route index element={<SearchLayout />} />
              <Route path="results" element={<ResultsLayout />}>
                <Route index element={<SearchResult />} />
                <Route path=":id" element={<VideoPage />} />
              </Route>
            </Route>

            <Route
              path="account"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route
                path="view"
                element={
                  <RequireAuth>
                    <AdminPage />
                  </RequireAuth>
                }
              >
                <Route index element={<AdminPlaylists />} />
                <Route path="videos" element={<AdminVideos />} />
                <Route path="videos/watch/:id" element={<VideoPage />} />
                {/*<Route path="files" element={<FilesWrapper />} />*/}
                {/*<Route path="files/watch/:id" element={<Video />} />*/}
              </Route>
              <Route path="edit" element={<Outlet />}>
                <Route path=":playlistId" element={<PlayListPageAdmin />}></Route>
                <Route path=":playlistId/:videoId" element={<AdminVideoOutletLazy />}>
                  <Route path="transcript" element={<EditTranscriptPage />} />
                  <Route path="summary" element={<EditSummaryPage />} />
                  <Route path="timecodes" element={<EditTimecodesPage />} />
                  <Route path="quiz" element={<EditQuizPage />} />
                </Route>
              </Route>
              {/* </Route> */}
            </Route>

            <Route path="/video" element={<VideoPage />} />
            <Route path="embed/video/:id/" element={<VideoPage />} />
            <Route path="/billing-success/" element={<SuccessPaymentPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
      {/* )} */}
      <Suspense>
        <Login />
        <Registration />
        <CreatePlaylist />
        <AddMobile />
        <AddVideo />
        <ResetPassword />
        <ResendEmail />
        <RemoveVideo />
        <RemoveVideoFile />
        <Notification />
        <Global isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />
        <VerifyInfo />
      </Suspense>
    </>
  );
}

export default App;

analytics.init();
analytics.sendGTAG();
