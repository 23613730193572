import { motion } from 'framer-motion';
import styled from 'styled-components';

import { theme, TitleH3 } from '@/styles';

export const Container = styled.ul`
  position: relative;
`;

export const Title = styled(motion.h1)`
  font-size: 24px;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 24px;

  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ContentCard = styled.div`
  background-color: ${theme.colors.modal.purple_50};
  border-color: ${theme.colors.modal.purple_300};
  transition: all 200ms;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${theme.colors.modal.purple_300};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  :hover {
    border-color: ${theme.colors.modal.purple_500};
  }
`;

export const ContentTitle = styled(TitleH3)`
  font-weight: 600;
  letter-spacing: -0.015em;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  margin-bottom: 6px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
