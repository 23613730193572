import { useTranslation } from 'react-i18next';
import { API_SHARE_URL } from '@/constants';
import { useActions } from '@/hooks';
import { useIframe } from '@/hooks/useIframe';
import { CopyButton, CopyButtonText } from '@/components/CopyIframeButton/CopyIframeButton.styles';

export type CopyIframeButtonPropsType = {
  playlistId?: string;
  videoId?: string;
};

export const CopyIframeButton = ({ playlistId, videoId }: CopyIframeButtonPropsType) => {
  const { t } = useTranslation('components');
  const { showNotification } = useActions();
  const { iframeTheme, iframeLang, vendorId, showTest } = useIframe();

  const copyIframe = async () => {
    let iframe = '';
    try {
      if (videoId) {
        iframe = `<iframe height="auto" src='${API_SHARE_URL}/embed/videos/${videoId}?t=0&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}'></iframe>`;
      } else if (playlistId) {
        iframe = `<iframe height="auto" src='${API_SHARE_URL}/embed/playlist/${playlistId}?vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}'></iframe>`;
      }
      await navigator.clipboard.writeText(iframe);
      showNotification({
        text: 'Встраиваемый код скопирован!',
        severity: 'success',
      });
    } catch (error) {
      showNotification({
        text: t('link', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  return (
    <CopyButton theme={'colored-default'} onClick={copyIframe}>
      <CopyButtonText>{t('copyIframe')}</CopyButtonText>
    </CopyButton>
  );
};
