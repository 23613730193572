import { usersAPI } from '../../../../api/usersApi';
import { useActions } from '../../../../hooks/useActions';
import { useHandlingError } from '../../../../hooks/useErrorHandling';
import Button from '../../Buttons/Button/Button';
import MyInput from '../../MyInput/MyInput';
import { ButtonsWrapper, Form } from '../Login/Login.styled';
import { Modal, Title } from '../Modal';
import { FieldsWrapper } from '../Registration/RegistrationModal/B2CForm/B2CForm.styled';
import { Inner } from '../RemoveUser/RemoveUser.styled';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/hooks';

export const CurrentPassword = () => {
  const { t } = useTranslation('components');
  const { closeModal } = useActions();

  const newPassword = useAppSelector((state) => state.modal.data?.newPassword);

  const { catchError } = useHandlingError();

  const [resetPassword, { isLoading }] = usersAPI.useSetPasswordMutation();

  const closeHandler = () => {
    closeModal();
  };

  const {
    register,
    formState: { errors },

    handleSubmit,
  } = useForm<{ currentPassword: string }>();

  const onSubmit = async ({ currentPassword }: { currentPassword: string }) => {
    try {
      if (newPassword) {
        await resetPassword({ currentPassword, newPassword }).unwrap();
      }
      closeHandler();
    } catch (err) {
      console.log(err);

      catchError(err);
    }
  };

  return (
    <Modal modal={'currentpassword'} onClose={closeHandler} closeButton>
      <Title>{t('change_password', { ns: 'modal' })}</Title>
      <Inner>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldsWrapper>
            <MyInput
              {...register('currentPassword', {
                required: { value: true, message: t('req_field', { ns: 'error' }) },
                minLength: { value: 6, message: t('min_char', { ns: 'error', length: 6 }) },
                maxLength: { value: 128, message: t('min_char', { ns: 'error', length: 128 }) },
              })}
              required
              type="password"
              autoComplete="off"
              label={t('form.cur_password', { ns: 'components' })}
              placeholder={t('form.cur_password', { ns: 'components' })}
              error={errors.currentPassword}
            />
          </FieldsWrapper>
          <ButtonsWrapper>
            <Button theme="white" type="submit" disabled={isLoading}>
              {t('form.send')}
            </Button>
            <Button theme="inline" type="reset" onClick={closeHandler}>
              {t('form.cancel')}
            </Button>
          </ButtonsWrapper>
        </Form>
      </Inner>
    </Modal>
  );
};
