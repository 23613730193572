import styled from 'styled-components';
import { theme } from '@/styles';

export const InputWrapper = styled.input`
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  background: rgb(26 17 69);
  border-radius: 10px;
  padding: 11px 16px;
  border: 1px solid ${theme.colors.modal.purple_300};
  color: ${theme.colors.modal.text.white};
  transition: all 0.3s;

  //  @media (max-width: 480px) {
  //  font-size: 15px;
  //}

  ::placeholder {
    color: ${theme.colors.modal.text.gray};
  }

  :hover {
    border: 1px solid ${theme.colors.modal.purple_500};
  }

  :focus-visible {
    border: 0.5px solid ${theme.colors.modal.purple_hover};
  }

  :disabled {
    border: 1px solid ${theme.colors.modal.purple_hover};
    cursor: auto;
  }
`;
