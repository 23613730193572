import { InfoBlockMaterials, TabItem, Tabs } from './VideoMaterialTabs.styled';

import { useTranslation } from 'react-i18next';

import Captions from '@/components/SvgIcons/Captions';
import { Clock } from '@/components/SvgIcons/Clock';
import QuizIcon from '@/components/SvgIcons/QuizIcon';
import SummaryIcon from '@/components/SvgIcons/SummaryIcon';
import { useAppSelector, useMatchMedia } from '@/hooks';
import { IframeThemeType, Video } from '@/types';
import { CSSProperties, useState } from 'react';
import { InfoText } from '@/pages/AdminPage/AdminPage.styled';

export const VideoMaterialTabs = ({
  onTabChange,
  tab,
  isIframe,
  iframeTheme,
  showTest,
  video,
}: {
  onTabChange: (value: number) => void;
  tab: number;
  isIframe?: boolean;
  iframeTheme?: string;
  showTest: string;
  video: Video | undefined;
}) => {
  const { t } = useTranslation('components');
  const isTablet = useMatchMedia('(min-width: 1200px)');
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [showInfoTariff, setShowInfoTariff] = useState(false);

  const onTabClickHandler = (tab: number) => {
    if (video?.transcriptionStatus !== 'NOT_ORDERED' && tab === 1) {
      onTabChange(1);
    }
    if (video?.timecodesStatus !== 'NOT_ORDERED' && tab === 2) {
      onTabChange(2);
    }
    if (video?.summaryStatus !== 'NOT_ORDERED' && tab === 3) {
      onTabChange(3);
    }

    if (video?.quizzStatus !== 'NOT_ORDERED' && tab === 4) {
      onTabChange(4);
    }
  };

  const getTabStyles = (isActive: boolean, isDisabled: boolean): CSSProperties => {
    const isLightTheme = isIframe && iframeTheme === IframeThemeType.light;

    return {
      opacity: isDisabled ? 0.5 : 1,
      backgroundColor: isLightTheme
        ? isActive
          ? 'rgb(228, 228, 255)'
          : 'transparent'
        : isActive
        ? 'rgb(59 42 126)'
        : 'rgb(26 17 69)',
      color: isLightTheme ? (isActive ? 'rgb(26 17 69)' : 'rgb(26 17 69)') : isActive ? 'white' : '#94A3B8',
    };
  };

  const onMouseOverHandler = (isDisabled: boolean) => {
    if (isDisabled) {
      setShowInfoTariff(true);
    }
  };

  return (
    <Tabs showTest={showTest} isAuth={isAuth} isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      {showInfoTariff && (
        <InfoBlockMaterials isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          <InfoText
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            style={{ fontSize: '14px', lineHeight: '18px' }}
          >
            Эти материалы недоступны на вашем тарифе. Попробуйте тариф с расширенными возможностями, чтобы открыть
            таймкоды, конспекты и тесты.
          </InfoText>
        </InfoBlockMaterials>
      )}
      <TabItem
        onMouseOver={() => onMouseOverHandler(video?.transcriptionStatus === 'NOT_ORDERED')}
        onMouseOut={() => setShowInfoTariff(false)}
        aria-disabled={video?.transcriptionStatus === 'NOT_ORDERED'}
        onClick={() => onTabClickHandler(1)}
        style={getTabStyles(tab === 1, video?.transcriptionStatus === 'NOT_ORDERED')}
      >
        <Captions />
        {isTablet && t('menu.transcript')}
      </TabItem>
      <TabItem
        onMouseOver={() => onMouseOverHandler(video?.timecodesStatus === 'NOT_ORDERED')}
        onMouseOut={() => setShowInfoTariff(false)}
        aria-disabled={video?.timecodesStatus === 'NOT_ORDERED'}
        onClick={() => onTabClickHandler(2)}
        style={getTabStyles(tab === 2, video?.timecodesStatus === 'NOT_ORDERED')}
      >
        <Clock color={'currentColor'} />
        {isTablet && t('menu.timecodes')}
      </TabItem>
      <TabItem
        onMouseOver={() => onMouseOverHandler(video?.summaryStatus === 'NOT_ORDERED')}
        onMouseOut={() => setShowInfoTariff(false)}
        aria-disabled={video?.summaryStatus === 'NOT_ORDERED'}
        onClick={() => onTabClickHandler(3)}
        style={getTabStyles(tab === 3, video?.summaryStatus === 'NOT_ORDERED')}
      >
        <SummaryIcon />
        {isTablet && t('menu.summary')}
      </TabItem>
      {(showTest === '1' || (isAuth && !isIframe)) && (
        <TabItem
          onMouseOver={() => onMouseOverHandler(video?.quizzStatus === 'NOT_ORDERED')}
          onMouseOut={() => setShowInfoTariff(false)}
          aria-disabled={video?.quizzStatus === 'NOT_ORDERED'}
          onClick={() => onTabClickHandler(4)}
          style={getTabStyles(tab === 4, video?.quizzStatus === 'NOT_ORDERED')}
        >
          <QuizIcon />
          {isTablet && t('menu.quiz')}
        </TabItem>
      )}
    </Tabs>
  );
};
