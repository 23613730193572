import styled from 'styled-components';

import { theme } from '@/styles';

export const FormWrapper = styled.div`
  position: relative;
  padding: 32px;
  background-color: rgb(37 26 86);
  border: 1px solid ${theme.colors.border.purple_500_20};
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    padding: 25px;
  }
`;

export const FormInnerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    background: linear-gradient(to left, #ec4899, #9333ea);
    border: none;
    border-radius: 8px;
    padding: 0 12px;
    height: 56px;
    color: ${theme.colors.white.white_100};
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    &:hover {
      background: linear-gradient(to left, #db2777, #7e22ce);
      transition: all 0.2s;
    }
  }
`;

export const InputWrapper = styled.div`
  label {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    user-select: none;
    color: ${theme.colors.text.white_100};
  }

  input,
  textarea {
    margin-top: 12px;
    border-color: ${theme.colors.border.purple_500_30};

    :hover {
      border-color: ${theme.colors.border.purple_500_30};
    }
    :focus {
      border-color: ${theme.colors.border.purple_500};
    }
  }
  input {
    padding: 4px 12px;
    height: 48px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
    }
  }
  textarea {
    padding: 16px;
    ::placeholder {
      color: ${theme.colors.text.purple_500};
      font-size: 18px;
    }
  }
`;
