import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { InfoBlock } from '@/pages/AdminPage/AdminPage.styled';
import { SecondaryText, theme } from '@/styles';
import { Title } from '@/components/Shared/Modals/Modal';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 10px;
  width: 100%;
`;

export const SecondaryTextErrorFormat = styled(SecondaryText)`
  font-size: 13px;
  line-height: 16px;
  color: #000046;
`;

export const SecondaryTextInfoBlock = styled(SecondaryText)`
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
`;
export const VideoFileErrorBlock = styled.div`
  background-color: #ef7a80;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoFileBlock = styled.div`
  background-color: #1f1efe;
  border-radius: 10px;
  padding: 8px 5px;
  margin-top: 6px;
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectPlaylistWrapper = styled(VideoFileBlock)<{ videoLinkValue?: string; selectedFile?: File | null }>`
  background-color: #251a56;
  position: relative;
  width: 100%;
  padding-top: 18px;
  padding-right: 18px;
  padding-left: 18px;
  margin-top: 25px;
  opacity: ${(props) => (!props.videoLinkValue && !props.selectedFile ? '0.4' : '1')};
`;

export const InfoBlockAddVideo = styled(InfoBlock)`
  top: 50%;
  left: 22px;
  width: 90%;
`;

export const SecondaryTextSelect = styled(SecondaryText)`
  width: max-content;
  color: #62548b;
`;

export const ErrorTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const TextError = styled.span`
  color: #ef7a80;
  font-size: 13px;
  font-weight: 400;
`;

export const LinkContact = styled(Link)`
  text-decoration: underline;
  color: ${theme.colors.text.white_80};

  :hover {
    color: #e4e4ff;
  }
`;

export const LinkVK = styled(Link)`
  text-decoration: underline;
  color: #ef7a80;

  :hover {
    color: #62548b;
  }
`;

export const LinkYT = styled(LinkVK)`
  margin-left: 5px;
  margin-right: 2px;
`;

export const TitleAddVideo = styled(Title)`
  font-size: 20px;
  font-weight: 600;
`;
export const TextAddVideo = styled.span`
  margin: 5px 0;
  font-size: 15px;
  color: #d8b4fe;
`;

export const ButtonArrowLeftModal = styled.button`
  width: 36px;
  height: 36px;
  color: #c084fc;

  &:hover {
    background-color: rgb(168 85 247 / 0.1);
    border-radius: 12px;
    color: rgb(216 180 254);
  }
`;
