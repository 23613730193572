import { closeModal, closeOverlay, LabelText, openModal, openOverlay, theme, TitleH4 } from '../../../../styles';

import styled, { css } from 'styled-components';

import { ModalType } from '@/store/slices/modalsSlice';

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: min-content;
    margin: auto 0;  */
  height: 100%;

  @media screen and (max-width: 550px) {
    width: 100%;
    padding: 0;
    align-items: flex-end;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${theme.colors.text.white_10};
  opacity: 0.8;
  /* scale: 0; */
  animation: 0.2s ${(props) => (props.open ? openOverlay : closeOverlay)} forwards normal;
  /* cursor: pointer; */
`;

export const Content = styled.div<{ open: boolean; modal: ModalType }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 20px;
  width: max-content;
  /* max-height: 800px;
    height: 100%; */
  min-width: 300px;
  max-height: 90vh;
  /* overflow-y: auto; */
  justify-content: center;
  height: max-content;

  border: 1px solid ${theme.colors.modal.purple_500_30};
  background-color: #1a1145;
  opacity: 0;
  /* scale: 0; */
  animation: 0.4s ${(props) => (props.open ? openModal : closeModal)} forwards normal;

  @media screen and (max-width: 550px) {
    width: 100%;
    padding: 24px 20px;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  @media screen and (max-width: 359px) {
    padding: 20px 15px;
  }

  ${(props) => {
    switch (props.modal) {
      case 'AddPlayList':
      case 'EditPlayList':
        return css`
          @media screen and (max-height: 800px) {
            height: 100%;
          }
        `;
      case 'AddPlayListVideo':
      case 'SuggestVideo':
        return css`
          width: 500px;
          @media screen and (max-height: 765px) {
            height: 100%;
          }
        `;
      case 'registration':
        return css`
          width: 100vw;
          max-width: 100%;

          @media (min-width: 768px) {
            width: 800px;
            border-radius: 10px;
          }
        `;
      case 'Video':
        return css`
          @media screen and (max-height: 645px) {
            height: 100%;
          }
        `;
      case 'login':
        return css`
          @media screen and (max-height: 437px) {
            height: 100%;
          }
        `;
      case 'AddVideo':
        return css`
          @media screen and (max-height: 448px) {
            height: 100%;
          }

          @media screen and (max-width: 526px) {
            width: 100%;
          }
          @media screen and (min-width: 526px) {
            width: 90%;
            max-width: 900px;
          }
        `;
      default:
        return css``;
    }
  }}
`;

export const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const ExitButtonWrapperContent = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Inner = styled.div<{ modal: ModalType }>`
  display: flex;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px; /* ширина scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
  }

  ${(props) => {
    switch (props.modal) {
      case 'AddPlayList':
      case 'EditPlayList':
        return css`
          @media screen and (max-height: 800px) {
            padding: 0 10px 0 0;
          }
        `;
      case 'AddPlayListVideo':
      case 'SuggestVideo':
        return css`
          overflow: visible;
          @media screen and (max-height: 765px) {
            padding: 0 10px 0 0;
          }
        `;
      case 'registration':
      case 'Video':
        return css`
          @media screen and (max-height: 645px) {
            padding: 0 10px 0 0;
          }
        `;
      case 'login':
        return css`
          @media screen and (max-height: 437px) {
            padding: 0 10px 0 0;
          }
        `;
      case 'AddVideo':
        return css`
          //height: 638px;
          display: block;
          @media screen and (max-height: 448px) {
            padding: 0 10px 0 0;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        `;
    }
  }}
`;

export const InnerAuth = styled(Inner)`
  flex-direction: column;
  gap: 16px;

  width: 100vw;
  max-width: 100%;

  @media (min-width: 768px) {
    width: 800px;
    border-radius: 10px;
  }
`;

export const Title = styled(TitleH4)`
  margin-bottom: 20px;
  padding-right: 20px;
`;

export const OtherText = styled(LabelText)`
  display: flex;
`;
